body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.card-img {
  height: 75px;
  margin-bottom: 15px;
}

.card-label {
  margin-bottom: 8px;
}

.card-description {

}

.card-container {
  padding-left: -15px;
  padding-right: -15px;
}


@media (min-width: 992px) {
  .card-container {
    width: calc(6/12 * 100% - 30px);
  }
}

/*.home-eo-how-container {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  min-height: 500px;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-right: 35px;
  padding-left: 35px;
}*/

@media (min-width: 992px) {
  .home-eo-how-dots {
    bottom: -135px;
  }
}

@media (max-width: 991px) {
  .how-eo-cards > * {
    margin-bottom: 50px;
  }

  .home-eo-how-wrapper {
    margin-bottom: 150px;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .home-eo-how-dots {
    bottom: -295px;
    width: 190px !important;
  }
}

@media (max-width: 767px) and (min-width: 576px) {
  .home-eo-how-dots {
    bottom: -295px;
    width: 120px !important;
  }
}

@media (min-width: 992px) {
  .how-eo-cards > * {
    margin-right: 15px;
    margin-bottom: 30px;
  }

  .how-eo-cards {
    margin-left: -15px;
    margin-right: -15px;
  }

  .how-eo-cards > *:first-child {
    margin-bottom: 100px;
  }
}

@media (min-width: 768px) {
  .home-eo-how-container {
    padding-right: 17.5%;
  }

  .home-eo-how-wrapper {
    padding-bottom: 140px;
    padding-top: 50px;
  }

  .home-eo-how-container {
    padding-bottom: 200px;
  }

  .home-eo-how-dots {
    background-position-y: 0px;
  }
}


@media (max-width: 767px) {
  .home-eo-how-container {
    padding-right: 35px;
  }

  .home-eo-how-wrapper {
    padding-bottom: 70px;
    padding-top: 125px;
  }

  .home-eo-how-dots {
    background-position-y: 40px;
  }
}

@media (min-width: 576px) {
  .home-eo-how-container {
    padding-bottom: 250px;
  }
  .home-eo-how-dots {
    width: 240px;
  }
}


@media (max-width: 575px) {
  .home-eo-how-dots {
    width: 80px;
  }

  .home-eo-how-dots {
    bottom: -60px;
  }

  .home-eo-how-container {
    padding-bottom: 350px;
  }
}

@media (max-width: 575px) and (min-width: 400px) {
  .home-eo-how-container {
    padding-bottom: 350px;
  }
}

@media (max-width: 399px) {
  .home-eo-how-container {
    padding-bottom: 175px;
  }
}

.home-eo-how-dots {
  position: absolute;
  z-index: 1;
  left: 0px;
}

.home-eo-how-wrapper {
  position: relative;
}

.sponsors-section-img {
  height: 75px;
}

.sponsors-section-description {
  text-align: start;
}

@media (min-width: 992px) {
  .sponsors-section-container {
    width: 250px;
  }
}

@media (max-width: 991px) {
  .sponsors-section-container {
    width: 220px;
  }
}

.sponsors-floating-img {
  position: absolute;
  z-index: 2;
  right: 0;
}

.sponsors-floating-disc {
  position: absolute;
  z-index: 1;
  left: 0px;
}

@media (max-width: 767px) {

}

@media (min-width: 1130px) and (max-width: 1199px){
  .sponsors-floating-disc {
    width: 100px;
    top: 75px;
  }
}

@media (min-width: 1200px) and (max-width: 1299px){
  .sponsors-floating-disc {
    width: 75px;
    top: 75px;
  }
}

@media (min-width: 1300px) {
  .sponsors-floating-disc {
    width: 100px;
    top: 75px;
  }
}


@media (min-width: 768px) and (max-width: 1129px) {
  .sponsors-floating-disc {
    width: 65px;
    top: 15px;
  }
}

.sponsors-floating-dot {
  position: absolute;
  z-index: 1;
  width: 80px;
  left: 10px;
  bottom: -40px;
}

.sponsors-floating-another-dot {
  position: absolute;
  z-index: 1;
  width: 75px;
  right: 0%;
  bottom: calc(50% - 75px);
}

.sponsors-label {
  margin-bottom: 15spx;
}


.sponsor-persona-tile {
  width: 200px;
  height: 200px;
  border: 3px solid #FBFBFB;
  background-image: -webkit-gradient(linear, left top, right bottom, from(#F8A59A), to(#EB5B49));
  background-image: linear-gradient(to bottom right, #F8A59A, #EB5B49);
  background-image: linear-gradient(to bottom right, #F8A59A, #EB5B49);
  background-image: -o-linear-gradient(to bottom right, #F8A59A, #EB5B49);
  border-radius: 28px;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  color: #FBFBFB;
}

.home-sponsors-container {
  position: relative;
  z-index: 2;
}

.sponsors-sections-container {
  max-width: 850px;
}

.home-sponsors-header {
  max-width: 600px;
}


@media (min-width: 768px) {

  .home-sponsors-container {

  }

  .home-sponsors-header {
    margin-bottom: 65px;
  }
}

@media (max-width: 575px) {
  .sponsors-sections-container > *:not(:last-child) {
    margin-bottom: 35px;
  }
}

@media (max-width: 767px) {

  .home-sponsors-container {

  }

  .home-sponsors-header {
    margin-bottom: 35px;
  }
}

@media (min-width: 992px) {
  .sponsors-sections-container {
    width: 100%;
  }

  .home-sponsors-cta {
    margin-top: 65px;
  }
}

@media (max-width: 991px) {
  .home-sponsors-cta {
    margin-top: 35px;
    margin-left: auto;
    margin-right: auto;
  }

  .home-sponsors-image-container {
    margin-bottom: 35px;
  }
}

.home-sponsors-image-container > img {
  width: 200px;
}

.home-sponsors-dots {
  background-color: #3E0359;
  position: relative;
}


.actions-label {
  font-size: 40px;
  color: #333333;
  font-family: 'Poppins', sans-serif;
  text-align: center;
}

.actions-description {
  font-size: 20px;
  color: #333333;
  font-family: 'Poppins', sans-serif;
  text-align: center;
}
@media (min-width: 1200px) {
  .sponsors-floating-img {
    width: 500px;
    top: -230px;
  }
}

@media (min-width: 768px) and (max-width: 1199px){
  .sponsors-floating-img {
    width: 500px;
    top: -125px;
  }
}


@media (min-width: 768px) {
  .home-sponsors-dots {
    padding-top: 100px;
    padding-bottom: 50px;
  }
  .actions-header {
    margin-top: 95px;
  }
}

@media (max-width: 767px) {
  .home-sponsors-dots {
    padding-bottom: 40px;
  }
  .actions-header {
    margin-top: 65px;
  }

}

@media (max-width: 767px) and (min-width: 576px) {
  .home-sponsors-dots {
    padding-top: 200px;
  }
  .sponsors-floating-disc {
    width: 100px;
    top: 15px;
  }
}

@media (max-width: 575px) {
  .sponsors-floating-disc {
    width: 50px;
    top: 15px;
  }
}

@media (max-width: 575px) and (min-width: 500px) {
  .home-sponsors-dots {
    padding-top: 250px;
  }
}

@media (max-width: 499px) {
  .home-sponsors-dots {
    padding-top: 150px;
  }
}

@media (max-width: 767px) and (min-width: 576px) {
  .sponsors-floating-img {
    width: 500px;
    top: -230px;
  }
}

@media (max-width: 575px) {
  .sponsors-floating-img {
    width: 100%;
    padding-left: 25px;
  }
}

@media (max-width: 575px) and (min-width: 400px) {
  .sponsors-floating-img {
    top: -230px;
  }
}


@media (max-width: 399px) {
  .sponsors-floating-img {
    top: -160px;
  }
}

.how-section-img {
  height: 75px;
}

.how-section-container {
  z-index: 2;
  position: relative;
  width: 225px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: white;
  height: 285px;
}

.how-section-header {
  padding-right: 15px;
}

.how-section-divider {
  background-color: #EB5B49;
  height: 1px;
  border: none;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 35px;
}

.how-section-icon-container {
  height: 30px;
  width: 30px;
  background-color: #EB5B49;
  border-radius: 100%;
}


.how-section-icon {
  height: 20px;
}

.home-organizers-cards > * {
  margin-right: 15px;
  margin-left: 15px;
  margin-bottom: 30px;
}

.home-organizers-cards {
  margin-left: -15px;
  margin-right: -15px;
}

.organizers-floating-img {
  position: absolute;
  z-index: 2;
  right: 0;
}

.organizers-floating-grid {
  position: absolute;
  z-index: 1;
  width: 285px;
  right: 30px;
  top: -111px;
}

.organizers-floating-freckles {
  position: absolute;
  z-index: 1;
  width: 75px;
  left: 0px;;
  top: -126px;
}

.organizers-floating-dot {
  position: absolute;
  z-index: 1;
  width: 23px;
  left: 20%;
  bottom: 10px;
}

.organizers-label {
  margin-bottom: 30px;
}


.organizers-persona-tile {
  width: 200px;
  height: 200px;
  border: 3px solid #FBFBFB;
  background-image: -webkit-gradient(linear, left top, right bottom, from(#F8A59A), to(#EB5B49));
  background-image: linear-gradient(to bottom right, #F8A59A, #EB5B49);
  background-image: linear-gradient(to bottom right, #F8A59A, #EB5B49);
  background-image: -o-linear-gradient(to bottom right, #F8A59A, #EB5B49);
  border-radius: 28px;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  color: #FBFBFB;
}

.home-organizers-container {
  position: relative;
  z-index: 2;
}


@media (min-width: 768px) {

  .home-organizers-container {
    padding-bottom: 65px !important;
  }

  .home-organizers-header {
    margin-bottom: 65px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .organizers-floating-img {
    width: 400px;
  }
}


@media (max-width: 767px) {
  .home-organizers-container {
    padding-top: 75px !important;
  }

  .home-organizers-header {
    margin-bottom: 35px;
  }
}

@media (max-width: 767px) and (min-width: 576px) {
  .organizers-floating-img {
    width: 500px;
  }

  .home-organizers-container {
    padding-bottom: 200px !important;
  }
}

@media (max-width: 575px) {
  .organizers-floating-img {
    bottom: -45px;
  }

  .organizers-floating-img {
    width: 100%;
    padding-left: 25px;
    padding-right: 25px;
  }
  .home-organizers-container {
    padding-bottom: 200px !important;
    padding-top: 75px !important;
  }
}

@media (min-width: 1200px) {
  .organizers-floating-img {
    width: 500px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .organizers-floating-img {
    width: 400px;
  }
}

@media (min-width: 992px) {
  .home-organizers-dots {
    padding-bottom: 60px;
  }

  .home-organizers-cta {
    margin-top: 65px;
  }
}

@media (max-width: 991px) {
  .home-organizers-dots {
    padding-bottom: 230px;
  }

  .home-organizers-cta {
    margin-top: 35px;
    margin-left: auto;
    margin-right: auto;
  }

  .home-organizers-image-container {
    margin-bottom: 35px;
  }
}

.home-organizers-header {
  max-width: 600px;
}

@media (min-width: 576px) {
  .organizers-floating-img {
    bottom: -80px;
  }

  .organizers-sections-container {
    width: 550px;
  }
}

.home-organizers-image-container > img {
  width: 200px;
}

.home-organizers-dots {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#EB5B49), to(#C24C3D));
  background-image: linear-gradient(to bottom, #EB5B49, #C24C3D);
  position: relative;
}


.actions-label {
  font-size: 40px;
  color: #333333;
  font-family: 'Poppins', sans-serif;
  text-align: center;
}

.actions-description {
  font-size: 20px;
  color: #333333;
  font-family: 'Poppins', sans-serif;
  text-align: center;
}

@media (max-width: 575px) {
  .organizers-sections-container > *:not(:last-child) {
    margin-bottom: 35px;
  }
}

@media (min-width: 768px) {
  .home-organizers-dots {
    padding-top: 80px;
  }
  .actions-header {
    margin-top: 95px;
  }


}

@media (max-width: 767px) {
  .home-organizers-dots {
    padding-top: 40px;
  }
  .actions-header {
    margin-top: 65px;
  }
}

.home-organizers-cards {

}

.home-organizers-how {
  padding-left: 0px !important;
}

@media (min-width: 992px) {
  .detail-start {
    text-align: start;
  }

  .detail-end {
    text-align: end;
  }
}

@media (max-width: 991px) {
  .detail-start {
    text-align: center;
  }

  .detail-end {
    text-align: center;
  }
}

.detail-detail {
  font-family: 'Poppins', sans-serif;
  line-height: normal;
  font-size: 24px;
  color: #FBFBFB;
}

.detail-detail-position {
  margin-bottom: 20px;
}

.detail-description {
  font-family: 'Montserrat Alternates', sans-serif;
  line-height: normal;
  font-size: 16px;
  color: #FBFBFB;
}

.detail-white {
  color: #FFFFFF;
}

.detail-black {
  color: #2C2E30;
}

@media (min-width: 768px) {
  .detail-btw {
    color: #2C2E30;
  }

  .detail-wtb {
    color: white;
  }
}

@media (max-width: 767px) {
  .detail-wtb {
    color: #2C2E30;
  }

  .detail-btw {
    color: white;
  }
}

.services-container {
  background-image: url(/static/media/dots.9a45c597.png), -webkit-gradient(linear, left bottom, left top, color-stop(40%, #680894), color-stop(40%, #FFFFFF));
  background-image: url(/static/media/dots.9a45c597.png), linear-gradient(to top, #680894 40%, #FFFFFF 40%);
  background-image: url(/static/media/dots.9a45c597.png), linear-gradient(to top, #680894 40%, #FFFFFF 40%);
  background-image: url(/static/media/dots.9a45c597.png), -o-linear-gradient(to top, #680894 40%, #FFFFFF 40%);
  background-position: top, 100%;
  background-size: cover, contain;
  background-attachment:scroll;
  background-attachment:initial;
  background-repeat: no-repeat;

}

.services-vine-container {
  padding: 20px;
  z-index: 1;
}


.services-description {

}

@media (min-width: 992px) {
  .services-wrapper {
    padding-right: 15px;
    padding-left: 15px;
    margin: auto;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .services-wrapper {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 768px) {
  .services-wrapper {
    max-width: 1200px;
  }

  .services-left {
    position: relative;
    top: -99px;
    padding-bottom: 215px;
  }

  .services-right {
    position: relative;
    top: 46px;
    padding-bottom: 246px;
  }

  .services-vine-container {
    padding-left: 60px;
    padding-right: 157px;
    height: 825px;
    position: relative;

  }


  .services-vine {
    position: absolute;

  }
}

@media (min-width: 768px) {
  .services-details {

  }
  .services-vine {
    max-height: 800px;

  }
  .services-container {
    background-size: cover, contain;
  }
}

.services-container {
  background-image: -webkit-gradient(linear, left top, right top, from(#EB5B49), to(#680894));
  background-image: linear-gradient(to right, #EB5B49, #680894);
  background-position: top, 100%;
  padding-top: 50px;
  background-attachment:scroll;
  background-attachment:initial;
  background-repeat: no-repeat;
}

@media (max-width: 767px) {
  .services-details {
    padding-bottom: 50px;
  }
  .services-vine {
    max-height: 600px;

  }

  .services-container {
    background-size: contain, contain;


  }

  .services-details > *:first-child {
    margin-bottom: 50px;
  }
}


.services-container {
  padding-top: 65px;
}

.services-details {
  z-index: 2;
}

.services-header {
  font-family: 'Poppins', sans-serif;
  line-height: normal;
  font-size: 40px !important;
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 35px;

}

.new-button {
  min-height: 40px;
  min-width: 143px;
  border: none;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 4px;
  letter-spacing: 1px;
  font-family: Poppins,sans-serif;
  font-size: 14px !important;
  font-weight: 500;
}

.button-transparent {
  border: none;
}

.button-sm {
  min-height: 40px !important;
  padding-top: unset !important;
  padding-bottom: unset !important;
}

.new-button:disabled {
  cursor: not-allowed !important;
}

.button-primary {
  color: white;
  background-color: #EB5B49;
}

.button-primary-circle {
  height: 50px !important;
  width: 50px !important;
  border-radius: 100%  !important;
  border: none;
}

.button-primary-border {
  color: #EB5B49 !important;
  border: 1px solid #EB5B49;
  background-color: transparent;
}

.button-tertiary {
  color: white;
  background-color: #680894;
}


.button-quarternary {
  color: #680894;
  background-color: transparent;
  border: none;
  border: 1px solid #680894;
}

.button-quintary {
  color: #333333;
  background-color: transparent;
  border: none;
  border: 1px solid #333333;
}

.button-senary {
  color: white;
  background-color: transparent;
  border: none;
  border: 1px solid white;
}

.button-gray {
  color: #333333;
  background-color: white;
  border: 1px solid #E0E0E0;
}

.button-secondary {
  color: #EB5B49 !important;
  background-color: transparent;
  border: 1px solid #EB5B49;
}

.button-secondary-circle {
  height: 50px !important;
  width: 50px !important;
  border-radius: 100%  !important;
  color: #EB5B49 !important;
  background-color: transparent;
  border: 1px solid #EB5B49;
}

.new-button:hover {
  cursor: pointer;
}

.button-button-group {
  width: 50px;
  min-width: unset !important;
}


@media (min-width: 768px) {
  .button-lg {
    min-width: 300px;
  }

  .button-md {
    min-width: 200px;
  }
}

@media (max-width: 767px) {
  .button-lg, .button-md {
    width: 100%;
  }
}

.button-full {
  width: 100%;
}

.button-gradient {
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  background-image: -webkit-gradient(linear, left top, right bottom, from(#9B4CBF), to(#680894));
  background-image: linear-gradient(to bottom right, #9B4CBF, #680894);
}

.footer-container {
  padding-top: 60px;
  padding-bottom: 60px;
}

.footer-container-pink {
  background: linear-gradient(45deg, #EB5B49, #F8A59A);
}

.footer-container-purple {
  background: linear-gradient(45deg, #680894, 65%, #E3DBE6);
}

.footer-main {
  background-color: rgba(227, 219, 230, .25);
  height: 100%;
  border-radius: 9px;
}

@media (min-width: 992px) {
  .footer-main {
    width: 85%;
  }
}

@media (min-width: 768px) {
  .footer-main {
    padding: 35px 45px 80px 45px;
  }
}

@media (max-width: 767px) and (min-width: 576px) {
  .footer-main {
    padding: 35px 40px 80px 40px;
  }
}

@media (max-width: 991px) {
  .footer-main {
    width: 100%;
  }


  .footer-ctas-slot {
    margin-top: 35px;
  }
}

@media (min-width: 576px) {
  .footer-top-row {
    margin-right: 45px;
  }
}


@media (max-width: 575px) {
  .footer-main {
    padding: 15px 45px 80px 15px;
  }

  .footer-top-row {
    margin-bottom: 45px;
  }
}

.benefit-label {
  line-height: normal;
  font-size: 40px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  color: #333333;
}

.benefit-label-position {
  margin-bottom: 50px;
}

.benefit-description {
  font-family: 'Montserrat', sans-serif;
  line-height: 34px;
  font-size: 20px;
  text-align: center;
  color: #333333;
}

.benefit-container {
  padding-top: 80px;
  padding-bottom: 50px;
}

@media (max-width: 767px) {
  .benefit-image-container {
    padding-top: 50px;
  }
}

.benefit-image {
  height: 100%;
  width: 100%;
}

.benefits-container {
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(/static/media/shapes.14df1bfc.png);
}

@media (min-width: 768px) {
  .benefits-container {
    background-position: 0 50%;
  }
}

@media (max-width: 767px) {
  .benefits-container {
    background-position: 0 50%;
  }
}

.benefits-container > *:first-child{
  margin-bottom: 120px;
}

.image {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.icon {
  cursor: pointer;
}

.social {
  height: 24px;
}

.hammer {
  height: 60px;
}

.modal-container {
  position: fixed;
  background-color: rgba(0, 0, 0,.5);
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 10;
  overflow-y: scroll;
}

.modal-contents {
  min-height: 100%;
  width: 100%;
}

.modal-wrapper {
  background-color: white;
}

.restage-main-modal, .restage-main-modal-lg {
  top: unset !important;
  display: flex;
}

.restage-main-modal-lg {
  padding-bottom: 0px !important;
  height: 100%;
}

.restage-antd-modal-wrapper-full {
  padding: 0px !important;
}


@media (min-width: 768px) {
  .restage-antd-modal-wrapper {

  }

/*
  .restage-main-modal > .ant-modal-content {
    height: 100% !important;
  }*/


  .restage-antd-modal-wrapper-lg {

  }

  .modal-container {
    padding-top: 90px
  }

  .restage-modal-header {
    height: 90px;
  }

  .modal-container, .modal-contents {
    padding-bottom: 90px;
    padding-left: 90px;
    padding-right: 90px;
  }

  .ant-modal-content {
    /*padding-bottom: 60px !important;*/
    /*padding: 60px !important;*/
  }
  .ant-modal {

    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
}

.ant-modal-content {
  flex-shrink: 0!important;
  width: 100%;
}

@media (max-width: 767px) {
  .ant-modal-content {
    /*padding: 60px !important;*/
  }

  .ant-modal {

    padding-top: 60px;
  }

  .restage-antd-modal-wrapper {

  }

  .restage-antd-modal-wrapper-lg {

  }

  .restage-modal-header {
    height: 50px;
  }

  .modal-container {
    padding-top: 50px;
  }

  .modal-container, .modal-contents {
    padding-bottom: 50px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

.modal-hidden {
  display: none;
}

.restage-modal-header {
  border: none;
  position: relative;
}

.modal-close {
  margin-right: 1rem;
  margin-top: 1rem;
  right: 0;
  position: absolute;
}

.restage-modal-footer {
  min-height: 50px;
}

.ant-modal-content {
  background-color: unset !important;
  margin-bottom: 60px;
  /*border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;*/
}

.ant-modal-body {
  height: 100% !important;
  padding: 0px !important;
  background-color: white !important;
  border-top-left-radius: 9px !important;
  border-top-right-radius: 9px !important;

}

.ant-modal-footer {
  /*position: absolute;*/
  width: 100%;
  bottom: 0;
  background-color: #F2F2F2 !important;
  /*z-index: 1;*/
}

/*
.restage-main-modal-sm. > .ant-modal-content {
  height: unset !important;
}*/

.restage-main-modal-xs > .ant-modal-content {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
}

.restage-main-modal-full > .ant-modal-content {
  height: 100%;
  width: 100%;
  margin: 0 !important;
}

.restage-main-modal-full {
  width: 100%;
  max-width: 100% !important;
  padding: 0px !important;
  height: 100%;
  top: 0px !important;
  margin: 0px !important;
}


.restage-main-modal-full > .ant-modal-content > .ant-modal-body {
  border-radius: 0px !important;
}

.ant-modal-wrap {

}
/*
.restage-main-modal-sm. > .ant-modal-content > .ant-modal-body {
  height:  !important;
}*/



@media (min-width: 768px) {
  .ant-modal-close {
    top: 40px !important;
    right: 40px !important;
  }
}

@media (max-width: 767px) {
  .ant-modal-close {
    top: 15px !important;
    right: 15px !important;
  }
}

.ant-modal-close-x {
  height: unset !important;
  width: unset !important;
  line-height: unset !important;
}

.ant-modal-footer button + button {
    margin-bottom: 0;
    margin-left: 0px !important;
}

.modal-close-background-dark-gray {
  background-color: rgba(0, 0, 0, .35);
}

.modal-close-background {
  padding: 10px;
  border-radius: 100%;
}


.body-copy-primary,
.body-copy-white,
.body-copy-error,
.body-copy-quarternary,
.body-copy-quintary,
.body-copy-tertiary-link,
.body-copy-secondary,
.body-copy-tertiary-bold,
.body-copy-white-semibold,
.body-copy-tertiary,
.body-copy-gray,
.body-copy-gray-4,
.body-copy-bold-tertiary-link,
.body-copy-brand-secondary {
  font-size: 16px;
}

.body-copy-brand-secondary {
  color: #828282;
}

.header-4-tertiary, .header-4-purple {
  font-size: 18px;
}

.body-copy-xs-small-brand-secoundary-3 {
  color: #828282;
  font-size: 10px;
  font-family: 'Montserrat', sans-serif;
}

.body-copy-tertiary-semibold, .body-copy-bold-light-gray, .body-copy-bold-orange, .body-copy-bold-orange, .body-copy-bold-tertiary, .body-copy-bold, .body-copy-bold-quintary, .body-copy-orange, .body-copy-orange-small, .body-copy-quarternary,  .body-copy-quintary  {
  font-family: 'Montserrat', sans-serif;
}

.body-copy-gray-4,
.body-copy-gray,
.body-copy-tertiary-semibold,
.body-copy-orange-small,
.body-copy-orange,
.body-copy-bold-orange,
.body-copy-bold-white,
.body-copy-bold-quintary,
.body-copy-bold-brand,
.body-copy-bold-light-gray,
.body-copy-bold,
.body-copy-white,
.body-copy-bold-tertiary,
.body-copy-bold-purple,
.body-copy-primary-small,
.body-copy-error-small,
.body-copy-secondary-small,
.body-copy-tertiary-small {
  font-size: 16px;
}
.body-copy-bold-tertiary-xs-small,
.body-copy-primary-xs-small,
.body-copy-error-xs-small,
.body-copy-secondary-xs-small,
.body-copy-tertiary-xs-small {
  font-size: 13px;
}

.body-copy-quintary,
.body-copy-quarternary,
.button-text,
.button-text-bold,
.button-text-medium,
.body-copy-bold-tertiary-xs-small,
.body-copy-error-xs-small,
.body-copy-error-small,
.body-copy-error,
.header-1-tertiary,
.header-4-tertiary,
.header-4-purple,
.body-copy-white,
.body-copy-bold-white,
.body-copy-tertiary,
.body-copy-gray,
.body-copy-gray-4,
.body-copy-white-semibold,
.body-copy-brand-secondary,
.body-copy-tertiary-link,
.body-copy-bold-tertiary-link,
.body-copy-tertiary-small,
.body-copy-tertiary-semibold,
.body-copy-primary,
.body-copy-primary-small,
.body-copy-secondary,
.body-copy-secondary-small {
  font-family: 'Montserrat', sans-serif;
}

.button-text-medium,
.body-copy-primary-xs-small,
.body-copy-error-xs-small,
.body-copy-error-small,
.body-copy-secondary-xs-small,
.body-copy-tertiary-xs-small,
.body-copy-white,
.body-copy-tertiary,
.body-copy-brand-secondary,
.body-copy-quarternary,
.body-copy-quintary,
.body-copy-tertiary-link,
.body-copy-gray,
.body-copy-gray-4,
.body-copy-tertiary-small,
.body-copy-tertiary-semibold,
.body-copy-primary,
.body-copy-primary-small,
.body-copy-secondary,
.body-copy-secondary-small {
  font-weight: 500;
}

.body-copy-bold-white, .body-copy-bold-tertiary-xs-small, .header-4-purple, .header-4-tertiary, .button-text-bold, .body-copy-bold-tertiary-link, .body-copy-bold-orange, .body-copy-bold-tertiary, .body-copy-bold, .body-copy-bold-quintary, .body-copy-bold-purple, .body-copy-bold-brand, .body-copy-bold-light-gray {
  font-weight: 700;
}

.link-brand-white,
.link-brand-secondary,
.button-text-red,
.button-text,
.button-text-bold,
.button-text-medium {
  font-size: 14px;
}

.header-3-tertiary, .header-3-purple, .header-3-white {
  font-size: 24px;
}

.link-brand-white,
.link-brand-secondary,
.button-text-red,
.header-3-tertiary,
.header-3-purple,
.header-3-white,
.header-2-brand,
.header-2-darkpurple,
.header-2-white,
.header-2-orange,
.header-2-tertiary,
.header-2-purple,
.header-2-mid-purple,
.header-2-quarternary,
.header-2-tertiary,
.header-2-secondary,
.header-2-gray,
.header-1-purple,
.header-1-white,
.header-1-tertiary-semibold,
.body-copy-tertiary-semibold {
  font-family: 'Poppins', sans-serif;
}

.header-2-mid-purple,
.link-brand-white,
.link-brand-secondary,
.header-2-brand,
.header-2-orange,
.header-2-tertiary,
.header-2-white,
.header-2-purple,
.header-2-darkpurple,
.header-2-quarternary,
.header-2-tertiary,
.header-2-secondary,
.header-2-gray,
.body-copy-white-semibold,
.header-1-tertiary-semibold,
.body-copy-tertiary-semibold  {
  font-weight: 600;
}

.body-copy-bold-quintary {
  color: #F8A59A;
}

.body-copy-bold-purple {
  color: #3E0359;
}

.body-copy-quintary {
  color: #828282;
}

.body-copy-bold-light-gray {
  color: #F8F8F8;
}


.body-copy-gray-4 {
  color: #BDBDBD;
}

.header-1-tertiary, .header-1-purple, .header-1-white {
  font-weight: 300;
}

.header-2-quarternary, .body-copy-bold, .body-copy-quarternary {
  color: #3E0359;
}

.body-copy-primary,
.body-copy-primary-small {
  color: #FBFBFB;
}

.header-1-white,
.header-3-white,
.body-copy-white-semibold,
.link-brand-white,
.body-copy-bold-white,
.body-copy-white {
  color: #FFFFFF;
}

.header-4-purple,
.header-3-purple,
.header-1-purple,
.header-2-mid-purple,
.body-copy-bold-brand,
.body-copy-secondary,
.link-brand-secondary,
.body-copy-tertiary-link,
.body-copy-secondary-small {
  color: #680894 !important;
}

.header-2-orange, .body-copy-bold-orange, .body-copy-orange, .body-copy-orange-small  {
  color: #EB5B49;
}

.header-2-midpurple {
  color: #3E0359;
}

.header-4-tertiary,
.header-3-tertiary,
.header-2-tertiary,
.header-1-tertiary,
.header-1-tertiary-semibold,
.body-copy-tertiary,
.body-copy-bold-tertiary-xs-small,
.body-copy-bold-tertiary,
.body-copy-bold-tertiary-link,
.body-copy-tertiary-small {
  color: #333333;
}

.header-1-white, .header-1-purple, .header-1-tertiary, .header-1-tertiary-semibold {
  font-size: 40px;
}

.header-2-darkpurple,
.header-2-gray,
.header-2-mid-purple,
.header-2-white,
.header-2-brand,
.header-2-orange,
.header-2-secondary,
.header-2-tertiary,
.header-2-quarternary,
.header-2-purple  {
  font-size: 28px;
}

.header-2-gray, .body-copy-gray {
  color: #F2F2F2;
}

.header-2-white,
.header-2-secondary  {
  color: white;
}

.header-2-brand,
.header-2-purple {
  color: #3E0359;
}

.header-2-darkpurple {
  color: #3E0359;
}

.header-2 {

}

.header-3-tertiary, .header-3-purple, .header-3-white {
  font-weight: 400;
}

.body-copy-tertiary-link {

}

.link-brand-white,.link-brand-secondary {
  letter-spacing: 1px;
}

.link-brand-secondary:hover,
.body-copy-bold-tertiary-link:hover,
.body-copy-tertiary-link:hover {
  cursor: pointer;
}

.body-copy-bold-tertiary-link {
  text-decoration: underline !important;
}

.restage-link {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  -webkit-transition: color .200s;
  transition: color .200s;
  cursor: pointer;
}

.restage-link:hover {
  color: #F8A59A !important;
}

.restage-link:hover > * {
  color: #F8A59A !important;
}

.restage-link:hover > * > path {
  -webkit-transition: fill 250ms;
  transition: fill 250ms
}

.restage-link:hover > * > path {
  fill: #F8A59A !important;
}

.restage-link:hover > * {
  -webkit-transition: color 250ms;
  transition: color 250ms
}

.field-required,
.body-copy-error,
.body-copy-error-small,
.body-copy-error-xs-small {
  color: red;
}

.body-copy-error-container {
  text-align: center;
  position: absolute;
}

.body-copy-error-wrapper {
  position: relative;
}

.button-text, .button-text-red {
  font-weight: 500;
}

.button-text-red {
  background-color: #EB5B49;
  letter-spacing: 1px;
}

.link-undecorated:hover {
  cursor: pointer;
}

.textfield, .textarea {
  background: #FFFFFF; /*#FBFBFB;*/
  padding-left: 11px;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;/*Montserrat medium*/
  color: #000000;
  position: relative;
  border: 1px solid #BECAD6;
  border-radius: 4px;/*9px;*/
  -webkit-transition-property: border-color;
        transition-property: border-color;
  -webkit-transition-duration: 300ms;
        transition-duration: 300ms;
    -webkit-transition-timing-function: ease;
          transition-timing-function: ease;
}

.ant-input, .ant-select-search__field {
  color: #000000 !important;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 16px !important;
  background-color: #FBFBFB;
}

.ant-input:focus, .textfield:focus, .textarea:focus {
  border-color: #680894 !important;
}

.textfield:hover, .textarea:hover {
  border-color: #40a9ff;
}

.textfield:focus, .textarea:focus {
  outline: none;
}

.textfield {
  height: 40px;
}

.textfield-sm {
  height: 40px;
}

.textarea {
  height: 150px;
  padding-top: 17px;
}

.textfield-left-rounded {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}


.textfield-left-not-rounded {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

@media (min-width: 768px) {
  .textarea-lg, .textfield-lg, .textfield-container-lg {
    max-width: 400px;
    width: 100%;
  }

  .textarea-md, .textfield-md, .textfield-container-md {
    max-width: 300px;
    width: 100%;
  }

  .textarea-half, .textfield-half, .textfield-container-half {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .textarea-lg,
  .textfield-lg,
  .textfield-container-lg {
    width: 100%;
  }

  .textarea-md,.textfield-md, .textfield-container-md, .textfield-half, .textfield-container-half {
    width: 100%;
  }
}

.textfield-full, .textfield-container-full, .textarea-full {
  width: 100%;
  /*min-width: 300px;*/
}

.textfield-error {
  color: red;
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
}

.textfield-error-container {
  position: absolute;
}

.textfield-hidden {
  display: none;
}

.signup-button {
  border-radius: 4px;
  padding-left: 10px !important;
  padding-right: 10px !important;
  /*background-image: linear-gradient(to bottom right, #9B4CBF, #680894);
  background-image: -webkit-linear-gradient(to bottom right, #9B4CBF, #680894);
  background-image: -moz-linear-gradient(to bottom right, #9B4CBF, #680894);
  background-image: linear-gradient(to bottom right, #9B4CBF, #680894);
  background-image: -o-linear-gradient(to bottom right, #9B4CBF, #680894);*/
}

.signup-textfield {
  border-radius: 4px !important;
}

.signup-confirmation {
  display: none;
}

.signup-confirmation.signup-confirmation-success {
  display: unset !important;
}

.signup-confirmation, .signup-confirmation.signup-confirmation-success {
  position: relative;
}

.signup-success {
  position: absolute;
  color: #0cd80c;
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
}

@media (min-width: 768px) {
  .signup-image {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .signup-image {
    width: 100%;
  }
}

@media (min-width: 576px) {
  .signup-button {
    margin-left: 8px;
  }
  .signup-textfield {
    width: 270px;
  }
}

@media (max-width: 575px) {
  .signup-button {
    margin-top: 8px;
  }
}

.signup-error-message {
  /*color: rgb(104, 8, 148);*/
  color: red;
  padding-top: 3px;
}

.hero-profile {
  max-height: 100%;
  max-width: 100%;
}

.hero-img {
  max-width: 300px;
  width: 100%;
  margin-bottom: 20px;
}

.hero-content {
  height: 100%;
}

.hero-main {
  height: calc(100% - 83px);
}

.hero-container {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 80%;
  padding-bottom: 25px;
  padding-top: 35px;
}


@media (min-width: 992px) {
  .hero-container {
    height: 100vh;
    min-height: 600px;
  }
}

@media(max-width: 991px) {
  .home-eo-how-dots {
      bottom: -135px;
  }

  .hero-cta-container {
    margin-top: 65px !important;
    margin-bottom: 35px;
    padding: 0px !important;
  }
}

.hero-sidedots {
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: -250px;
}

@media (min-width: 768px) {
  .hero-sidedots {
    width: 360px;
  }
}

@media (min-width: 768px) {
  .hero-container {
    background-image: url(/static/media/hero.8f42e91d.svg);
  }
}

@media (max-width: 767px) {
  .hero-container {
    background-image: url(/static/media/hero-mobile.8be9e9d9.svg);
  }

  .hero-sidedots {
    width: 195px;
  }

  .hero-logo-container {
    margin-bottom: 35px;
  }

  .hero-container {
    padding-top: 25px;
  }

  .hero-rocket-container {
    /*padding: 0px !important*/
  }
}

.hero-description {
  font-size: 24px;
  text-align: start;
  color: #FFFFFF;
  font-family: 'Montserrat', sans-serif;
}

.hero-text {
  /*margin-bottom: 50px;*/
  margin-bottom: 10px;
}

@media(min-width: 768px) and (max-width: 991px) {
  .banner-slider-container {
    padding-top: 200px;
    padding-bottom: 200px;
  }
}

@media(min-width: 992px) {
  .banner-slider-container {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}


@media(min-width: 576px) and (max-width: 767px) {
  .banner-slider-container {
    padding-top: 100px;
    padding-bottom: 150px;
  }
}

@media(max-width: 575px) {
  .banner-slider-container {
    padding-top: 150px;
    padding-bottom: 275px;
  }

  .banner-slider-slide {
    padding-right: 34px;
    width: 100%;
  }

  .banner-container {
    position: relative;
    padding-left: 15px !important;
    padding-right: 15px !important;

  }

}

.banner-container {
  position: relative;
}

.home-dots {
  position: absolute;
  top: calc(50% - 60px);
  left: 5%;
}

.container {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.home-wrapping-up-block {
  position: relative;
  padding-top: 125px;
  padding-bottom: 125px;
}

@media (min-width: 768px) {
  .home-wrapping-up-block {
    padding-right: 20% !important;
  }
}

.home-strikethrough-container {
  position: relative;
}

.home-strikethrough {
  height: 3px;
  left: 0px;
  right: 0px;
  position: absolute;
  background-color: #3E0359;
}

@media (min-width: 768px) {
  .restage-navbar {
    height: 85px;
    width: 100%;
    background-color: #680894;
    padding-left: 75px;
    padding-right: 45px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }


  .restage-logo {
    height: 48px;
    margin-right: 65px;
  }

  .restage-user-link {
    height: 45px;
    float: right;
  }

  .restage-user-link-container {
    width: 100%;
  }

  .restage-user-link-container:hover {
    cursor: pointer;
  }

  .restage-hamburger {
    display: none;
  }

  .restage-sidenav {

  }

  .restage-sidenav-contents > *:not(:last-child) {
    margin-bottom: 25px;
  }

  .restage-sidenav {
    width: 300px;
    height: 315px;
  }
}

@media (max-width: 767px) {

  .restage-navbar {
    height: 85px;
    width: 100%;
    background-color: #680894;
    padding-left: 15px;
    padding-right: 15px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .restage-logo {
    height: 48px;
    margin-right: 65px;
  }

  .restage-user-link {
    height: 45px;
    float: right;
  }

  .restage-user-link-container {
    display: none;
  }

  .restage-sidenav {
    width: 100%;
    height: 270px;
    background-color: #680894;
  }

  .restage-sidenav-contents > *:not(:last-child) {
    margin-bottom: 25px;
  }
}

.restage-sidenav {
  right: 0;
  box-shadow:  rgba(0, 0, 0, 0.1) 0px 0px 5px ;
  overflow: hidden;
  background-color: #680894;
  z-index: 10;
  position: absolute;
  -webkit-transition-property: height, width;
        transition-property: height, width;
  -webkit-transition-duration: 500ms;
        transition-duration: 500ms;
}

.restage-sidenav-hidden {
  height: 0% !important;
}

.restage-sidenav-contents {
  padding: 25px;
}

.restage-divider {
  height: 1px;
  width: 100%;
}

.restage-divider-gradient {
  background: radial-gradient(closest-side, #680894, #EB5B49);
}

.restage-divider-brand-secondary {
  background-color: #828282;
}

.restage-divider-brand {
  background-color: #E3DBE6;
}

.navbar-container {
  height: 70px;
  background: -webkit-gradient(linear, left top, right top, from(#680894), to(#3E0359));
  background: linear-gradient(to right, #680894, #3E0359);
  z-index: 3 !important;
  position: relative;
}

.navbar-container-landing {
  background: transparent !important;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 1;
}


@media (min-width: 768px) {
  .navbar-container {
    padding-left: 35px;
    padding-right: 40px;
  }

  .navbar-links > *:not(:last-child){
    margin-right: 20px;
  }


  .navbar-mobile-link {
    display: none;
  }

  .navbar-tooltip-row > *:not(:last-child) {
    margin-right: 60px;
  }

}

@media (min-width: 1200px) {
  .navigation-links-menu-right {
    display: none !important;
  }
}

@media (max-width: 1199px) {
  .restage-user-link {
      display: none;
  }
  .navigation-links-right {
    display: none !important;
  }
  .navbar-links {
    display: none !important;
  }
}

@media (max-width: 767px) {

  .navbar-tooltip-resource-link {
      display: none;
  }

  .navbar-container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .navbar-mobile-link {

  }
}

.navbar-logo {
  width: 166px;
}

.navbar-logo:hover {
  cursor: pointer;
}

.create-event-button {
  margin-right: 8px;
}

.ant-tooltip, .ant-tooltip-content {
  width: 100% !important;
  max-width: 630px !important;
}

.ant-tooltip-content {
  margin-left: 25px;
}

.ant-tooltip-inner {
  background-color: #E3DBE6 !important;
}

.ant-tooltip-arrow::before {
  background-color: #E3DBE6 !important;
}

.ant-tooltip-arrow {
  border-top-color: #E3DBE6 !important;
  border-left-color: #E3DBE6 !important;
}

.navbar-mobile-links {
  padding-top: 30px;
  padding-bottom: 30px;
  overflow-y: 100%;
}

.navbar-mobile-link {
  padding-top: 15px;
  padding-bottom: 15px;
}

.navbar-resources-submenu > .ant-collapse-header {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  padding-left: 0px !important;
}

.navbar-resources-submenu > .ant-collapse-content > .ant-collapse-content-box > *:not(:last-child) {
  margin-bottom: 15px;
}

.navbar-hidden {
  display: none !important;
}

.navbar-container-background-white {
    background-color: white !important;
    background-image: unset;
    border-bottom: 1px solid #F2F2F2;
}

@media (min-width: 768px) {
  .restage-form-group > *:not(:last-child) {
    margin-right: 30px;
  }

  .restage-form-group {

  }
}


@media (max-width: 767px) {
  .restage-form-group > *:not(:last-child) {
    margin-bottom: 30px;
  }

  .restage-form-group {

  }
}

.restage-form-group {
  width: 100%;
}



  /*Experiment Below*/
/*
  .restage-form-group:not(:last-child) {
    margin-bottom: 25px;
  }
*/

@media (min-width: 768px) {
  .restage-button-group > *:not(:last-child) {
    margin-right: 7px;
  }
}

.restage-button-group-horizontal > *:not(:last-child) {
  margin-right: 7px;
}


@media (max-width: 767px) {
  .restage-button-group > *:not(:last-child) {
    margin-bottom: 30px;
  }

  .restage-button-group {

  }
}

.restage-button-group {
  width: 100%;
}

.ant-select-search__field, .ant-select-dropdown-menu-item {
  color: #000000 !important;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.ant-select-dropdown-menu-item {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.autocomplete-container {
  position: relative;
  z-index: 1;
}

.autocomplete-options {
  position: absolute;
  width: 100%;
  box-shadow: 0 3px 28px 0 rgba(0,0,0,0.18);
  z-index: 1;
}

.autocomplete-option {
  background-color: #FFFFFF;
  color: #000000;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  width: 100%;
  min-height: 50px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.autocomplete-option:hover {
  cursor: pointer;
  background-color: #F2F2F2
}

.autocomplete-hidden {
  display: none;
}

.textfield-error {
  color: red;
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
}

.textfield-error-container {
  position: absolute;
}

.textfield-hidden {
  display: none;
}

.ant-input {
  border-radius: 4px !important;
}

.ant-select-selection__placeholder {
  color: #6c757d !important;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 16px !important;
  background-color: #FFFFFF;
  font-weight: 500;

}

.ant-select-selection {
  border-radius: 4px !important;
}

.restage-form {
  padding-left: 0px !important;
}

.restage-side-panel {
  overflow-y: scroll;
}

@media (max-width: 767px) {
  .restage-side-panel {
    margin-bottom: 50px;
    padding-left: 0px !important;
  }

  .restage-form {
    padding-right: 0px !important;
  }
}

.restage-side-panel {
  padding-right: 0px !important;
}

@media (min-width: 768px) {
  .restage-side-panel-docked {
    right: 0px;
    top: 85px;
    position: fixed;
    bottom: 0px;
  }
}

/*
.restage-form > *:not(:last-child) {
  margin-bottom: 25px;
}*/

.profile-container > *:not(:last-child) {
  margin-bottom: 25px;
}

.profile-container > *:nth-last-child(2) {
  margin-bottom: 50px !important;
}

@media (min-width: 768px) {
  .restage-wrapper {
    padding-top: 65px;
    padding-bottom: 65px;
  }
}

@media (max-width: 767px) {
  .restage-wrapper {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.restage-wrapper {
  
}

.tab-container {
  padding-bottom: 15px;
  padding-top: 15px;
}

.tab-container:hover {
  cursor: pointer;
}

@media (max-width: 767px) {
  .tab-label {
    /*display: none;*/
  }

  .tab-icon {
    margin: auto;
  }
}

@media (min-width: 768px) {
  .tab-label {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media (max-width: 767px) {
  .tab-container {

  }
  .tab-label {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.tab-container.inactive {
}

.tab-container.active {

}

.tab-icon {
 height: 22px;
}

.tab-checkmark {
  width: 16px !important;
}

@media (min-width: 768px) {
  .tabgroup-container > * {
    padding-right: 10px;
    padding-left: 10px;
  }
}

@media (max-width: 767px) {
  .tabgroup-container {
    width: 100%;
  }
}

@media (max-width: 767px) and (min-width: 400px) {
  .tabgroup-container > * {
    padding-right: 10px;
    padding-left: 10px;
  }

}

@media (max-width: 399px) {
  .tabgroup-container > * {
    padding-right: 5px;
    padding-left: 5px;
  }
}

.tabgroup-container {

}


.restage-eyebrow-container {
  border-bottom: 2px solid #680894;
}

.paginated-button-group-container > *:not(:last-child) {
  margin-right: 15px;
}

.paginated-button-group-container > * {
  margin-bottom: 15px;
}

.restage-pdf-link {
  margin-bottom: 10px;
}

.restage-pdf-section {
  margin-bottom: 25px;
}

@media (min-width: 768px) {
  .pdf-search-group {
    width: 50%
  }
}

@media (max-width: 767px) {
  .pdf-search-group {
    width: 100%;
    margin-top: 15px;
  }
}

.checkbox-label {
  margin-bottom: 5px important;
  margin-left: 10px;
}

.checkbox-text {
  margin-left: 35px;
}

.checkbox-container:hover {
  cursor: pointer;
}

.checkbox-container {
  padding-left: 0px;
  padding-right: 0px;
}

.checkbox-label:hover {
  cursor: pointer;
}

.checkbox-inner.checkbox-open {
 -webkit-transform: scale(.9);
         transform: scale(.9);
}

.checkbox-inner {
   background-color: #680894;
   border-radius: 5px;
    height: 100%;
    width: 100%;
  -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition-property: transform;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: .30s;
    transition-duration: .30s;
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease;
    -webkit-transition-delay: initial;
    transition-delay: 0s;
    transition-delay: initial;
}

.checkbox-outer {
  height: 25px;
  width: 25px;
  border: 1px solid #680894;
  border-radius: 5px;
}

.negotiations {
  padding: 0%;
}

.negotiations-modal {
  height: 100%;
}


.negotiations-wrapper {
  padding-right: 0px;
  padding-left: 0px;
  position: relative;
}

@media (min-width: 768px) {
  .negotiations-wrapper {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .negotiations-footer {
    padding-bottom: 30px;
  }

  .negotiations-footer {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

@media (max-width: 767px) {
  .negotiations-wrapper {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .negotiations-footer {
    padding-bottom: 15px;
  }

  .negotiations-footer {
    width: 100% !important;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .negotiations-footer {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

.negotiations-messages {
  overflow-y: scroll;
  padding-bottom: 204px;
}

.negotiations-footer {
  background-color: #F8F8F8;
  position: fixed;
  bottom: 0px;
  width: 100%;
  left: 0px;
  right: 0px;
}

/*
.negotiations-modal-body {
  padding-left: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
}
*/
.negotiations-package-name {
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  color: #680894;
  padding-top: 30px;
}

.negotiations-package-description {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 151.1%;
  padding-right: 6%;
  padding-top: 10px;
}

.negotiations-negotiate-pacakge {
  padding-top: 20px;
}

.negotiations-divider {
  height: 1px;
  background-color: #E3DBE6;
  margin-top: 30px;
}

.negotiations-sub-container {
  padding-top: 10px;
}

.negotiations-button-group {
  background: #F2F2F2;
  border-radius: 0px 0px 8px 8px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}


.blog-container > *:not(:last-child) {
  margin-bottom: 25px;
}

.blog-container {
  padding-top: 50px;
  padding-bottom: 50px;
}

.blog-tile:hover {
  cursor: pointer;
}


.listitem > *:first-child {
  margin-right: 15px;
}

@media (min-width: 768px)  {

  .listitem:not(:nth-last-child(2)) {
    margin-bottom: 15px;
  }
}

.listitem:not(:last-child) {
  margin-bottom: 15px;
}


.subscribemodal-container {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#680894), to(#EB5B49));
  background-image: linear-gradient(to bottom, #680894, #EB5B49);
  height: 100%;
  padding: 20px;
  position: relative;
}

.subscribemodal-container {
  width: 100%;
}

.subscribemodal-bubbles-top, .subscribemodal-bubbles-right {
  position: absolute;
  z-index: 1;
  height: 350px;
}

.subscribemodal-bubbles-top {
  top: 0px;
  left: 0px;
}

.subscribemodal-bubbles-right {
  right: 0px;
  top: 0px;
}

.subscribemodal-logo {
  bottom: 50px;
  right: 50px;
  position: absolute;
}

.subscribemodal-purple {
  color: #3E0359;
}

.postauthor-container {
  padding: 40px;
  background-color: #F2F2F2;
  border-radius: 9px;
}

.postauthor-socialurl {
  cursor: pointer;
  text-decoration: underline;
}

.postauthor-img {
  height: 75px;
  width: 75px;
  border-radius: 100%;
  background-size: 75px;
  background-position-y: -1px;
}

@media (min-width: 992px) {
  .postauthor-cta {
    border-left: 1px solid #333333;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .postauthor-cta-description {
    max-width: 285px;
    padding-right: 15px;
  }
}

@media (max-width: 991px) {
  .postauthor-cta-description {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.tableofcontents-container {
  background-color: #F2F2F2;
  border-radius: 9px;
}

.tableofcontents-link:hover {
  cursor: pointer;
}

.tableofcontents-index {
  width: 15px;
}

@media (min-width: 768px) {
  .tableofcontents-index {
    margin-right: 20px;
  }

  .tableofcontents-container {
    padding: 30px 90px 30px 90px;
  }
}

@media (max-width: 767px) {
  .tableofcontents-index {
    margin-right: 15px;
  }

  .tableofcontents-container {
    padding: 15px;
  }
}

.tableofcontents-header, .tableofcontents-item:not(:last-child) {
  margin-bottom: 10px;
}

.tableofcontents-link:hover {
  color: #3E0359;
}

.postmediasticky-container {
  left: 5%;
  width: 70px;
  padding-top: 100px;
  position: absolute;
}

.postmediasticky-container > *:not(:last-child) {
  margin-bottom: 30px;
}

.postmediasticky-icon {
  cursor: pointer;
  color: #F8A59A;
}

.postmediamobile-icons > *:not(:last-child) {
  margin-right: 30px;
}

.postmediamobile-container {
  padding: 30px;
}

.postmediamobile-icon {
  cursor: pointer;
  color: #F8A59A;
}

@media (min-width: 992px) {
  .postmediamobile-container {
    display: none;
  }

}

.pill-container  {
  border-radius: 20px;
  min-width: 75px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #680894;
}

.pill-close-x {
  fill: #FBFBFB;
}
.pill-close {
  margin-left: 15px;
}

.post-wrapper {
  background-color: #FFFFFF;
}

.post-hero {
  width: 100%;
}

.post-authorwrapper {
  max-width: 880px;
  width: 100%;
  margin-top: 50px;
}

@media (min-width: 992px) {
  .post-hero {
    max-width: 960px;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 50px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 1200px) {
  .post-hero {
    max-width: 1140px;
  }
}



.post-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.post-container > *:not(:last-child) {
  margin-bottom: 25px;
}

.post-container {
  padding-top: 50px;
  padding-bottom: 50px;
}

@media (min-width: 576px) {
  .post-container {
    max-width: 540px;
  }

  .post-about-restage-container {
    padding: 40px;
  }
}

@media (max-width: 575px) {
  .post-about-restage-container {
    padding: 15px;
  }
}

@media (min-width: 768px) {
  .post-container {
    max-width: 720px;
  }
}

@media (max-width: 991px) {
  .post-stickycontainer {
    display: none !important;
  }
}

.post-blockquote {
  padding-left: 15px;
  border-left: 6px solid #F2F2F2;
}

.post-about-restage-container {
  padding: 40px;
  background-color: #F2F2F2;
  border-radius: 9px;
  max-width: 880px;
  margin-bottom: 20px;
}

.post-about-restage-header {
  margin-bottom: 10px;
}

/*
@media (min-width: 992px) {
  .post-container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .post-container {
    max-width: 1140px;
  }
}
*/

.accordionpanel-body {
  overflow-y: hidden;
  width: 100%;
  border-left: 1px solid #BECAD6;
  border-right: 1px solid #BECAD6;
  -webkit-transform-origin: top;
          transform-origin: top;
  height: auto;
  -webkit-transition-property: height;
        transition-property: height;
  -webkit-transition-duration: 500ms;
        transition-duration: 500ms;
    -webkit-transition-timing-function: ease;
          transition-timing-function: ease;
}

.accordionpanel-body > * {
  padding: 15px;
}

.accordionpanel-header {
  padding: 15px;
  border: 1px solid #BECAD6;
}

.accordionpanel-header:hover {
  cursor: pointer;
}

.accordionpanel-body.accordion-closed {
  height: 0px !important;
  border: none !important;
}

.accordionpanel-container {
  width: 100%;
}

.accordionpanel-icon-container {
  margin-right: 15px;
}

.accordionpanel-icon {
  width: 19px;
}

.accordionpanel-header.accordion-open {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.accordion-container {
  width: 100%;
  border-radius: 5px;
}

.accordion-panel {
  /*background-color: #333333;*/
  background-color: white;
}

.accordion-container > .accordionpanel-container:first-child > .accordionpanel-header {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.accordion-container > .accordionpanel-container:last-child > .accordionpanel-header {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.accordion-container > .accordionpanel-container:last-child > .accordionpanel-body {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom: 1px solid #BECAD6;
}

.ant-collapse > .ant-collapse-item {
    border-bottom: 1px solid #E3DBE6 !important;
}

.ant-collapse > .ant-collapse-item:first-child{
    /*border-top: 1px solid #E3DBE6 !important;*/
}

.ant-collapse-content {
    border-top: none !important;
}

.ant-collapse {
    border: none !important;
    background-color: transparent !important;
}

.ant-collapse-header > i {
  color: #680894;
}

.gallery-container {
  position: relative;
  width: 100%;
  /*background-color: #680894;*/
  z-index: 0;
  height: 100%;
  /*border-top-left-radius: 9px;
  border-top-right-radius: 9px;*/
  border-radius: 9px;
  overflow: hidden;
}

.gallery-dots > *:not(:last-child) {
  margin-right: 5px;
}

.gallery-dot {
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background-color: #FFFFFF;
}

.gallery-dot-active {
  background-color: #680894;
  -webkit-transition-property: color;
        transition-property: color;
  -webkit-transition-duration: 250ms;
        transition-duration: 250ms;
    -webkit-transition-timing-function: ease;
          transition-timing-function: ease;
}

/*
.gallery-image {
  width: 100%;
}

.gallery-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
*/

.gallery-image {
  width: 100%;
  height: 100%;
  max-height: 80vh;
  object-fit: cover;
}

.gallery-background-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  width: 100%;
}

.gallery-dots {
  position: absolute;
  bottom:  30px;
  width: 100%;
}

.gallery-button:disabled {
    cursor: not-allowed;
}

.gallery-button {
  width: 35px;
  height: 35px;
  z-index: 2;
  background-color: #FFFFFF;
  border: none;
  opacity: .35;
  cursor: pointer;
  border-radius: 100%;
  padding: 0px !important;
  position: absolute;
}

.gallery-button-left {
  left: 0px;
  position: absolute;
}

.gallery-button-right {
  right: 0px;
  position: absolute;
}

.gallery-button-left > button {
  left: 20px;
}

.gallery-button-left > img {
  left: 30px;
}

.gallery-button-right > button {
  right: 20px;
}


.gallery-button-right > img {
  right: 30px;
}

.gallery-button-left, .gallery-button-right, .gallery-swipeableviews, .gallery-image-container {
  height: 100%;
}

.gallery-caret {
  position: absolute;
  z-index: 1;
  cursor: pointer;
}

.react-swipeable-view-container {
  height: 100%;
}

.react-swipeable-view-container > *:not(:last-child) {
  background-color: transparent;
}

.event-gallery-modal-wrapper > .ant-modal-content > .ant-modal-body {
  border-radius: 9px !important;
}

.eventsidepanel-wrapper > *:not(:last-child){
  margin-bottom: 25px;
}

.event-details-main {
  border-radius: 9px;
}

@media (min-width: 768px)  {
  .eventsidepanel {
    padding-left: 0px;
  }
  
  .eventsidepanel-container {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 50px;
  }
}

@media (max-width: 767px)  {
  .eventsidepanel {
    margin-bottom: 30px;
    padding-left: 0px;
    padding-right: 0px
  }

  .eventsidepanel-container {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: 25px;
  }
}


.eventsidepanel-container {
  border-radius: 9px;
  background-color: white;
}

.event-audience-tab-genders > *:not(:last-child) {
  margin-bottom: 25px;
}

.event-audience-tab-details > :not(:last-child) {
  margin-bottom: 50px;
}


.event-audience-tab-attendees > *:not(:last-child) {
  margin-right: 10%;
}


.event-details-main {
  border-radius: 9px;
}

.admin-review-container {
  padding: 30px 20px;
}

@media (min-width: 768px)  {
  .event-details-main {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .event-details-container {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 50px;
  }

  .event-page-wrapper {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 115px;
  }

}

@media (max-width: 767px)  {
  .event-page-wrapper > *:first-child {
      margin-bottom: 15px;
  }

  .event-details-main {

  }
  .event-container {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px !important;
  }

  .event-information {
    padding-bottom: 130px;
    padding-left: 15px;
  }

  .event-gallery {
    position: relative !important;
  }

  .event-details-container {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: 25px;
  }

  .event-page-wrapper {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: 100px;
  }
}

.image-gallery-image img {
 max-height: 400px;
}

.event-gallery {
  height: 300px !important;
}

.event-sticky-container {
  height: 100%;
}

.event-gallery:hover {
  cursor: pointer;
}

.event-details-wrapper > *:not(:last-child) {
  margin-bottom: 25px;
}

.event-details-container {
  border-radius: 9px;
  background-color: white;
}

/*.event-details-panel {
  padding: 15px;
}*/

.event-details-pill-wrapper > *:not(:last-child){
  margin-bottom: 15px;
}

.event-details-panel {
  padding-top: 30px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
}

.event-details-audience-attributes > *:not(:last-child) {
  margin-bottom: 10px;
}

.event-details-packages-content > :not(:last-child) {
  margin-bottom: 15px;
}


.checkbox-wrapper > *:not(:last-child){
  margin-bottom: 15px;
}


.event-details-pill-wrapper > * {
  margin-bottom: 15px;
  margin-right: 15px;
}

.expand-container {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 4px;
  border: 1px solid #680894;
}

.expand-container:hover {
  cursor: pointer;
}
.expand-children-container {
  background-color: white;
  position: absolute;
  padding: 15px;
  border: 1px solid #BECAD6;
  border-radius: 4px;
  z-index: 2;
}
/*.expand-children-container {
  background-color: white;
  position: absolute;
  min-width: 100%;
  height: 0%;
  -webkit-transition-property: height;
   -moz-transition-property: height;
     -o-transition-property: height;
        transition-property: height;
  -webkit-transition-duration: 500ms;
   -moz-transition-duration: 500ms;
     -o-transition-duration: 500ms;
        transition-duration: 500ms;
    -webkit-transition-timing-function: ease;
     -moz-transition-timing-function: ease;
       -o-transition-timing-function: ease;
          transition-timing-function: ease;
}
*/
.expand-children-container-open {
  height: 100% !important;

}

@media (min-width: 768px) {
  .expand-children-container {
    min-width: 300px;
  }
}

@media (max-width: 767px) {
  .expand-children-container {
    min-width: 100%;
  }
}

.admin-events-panel {
  background-color: white;
  padding-left: 40px;
  padding-right: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.admin-events-panel-container > *:not(:last-child) {
  border-bottom: 1px solid #e3dbe6  !important;
}

.ant-calendar-input, .ant-calendar-picker-input {
  color: #000000 !important;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 16px !important;
  background-color: #FBFBFB;
  font-weight: 500;
}

/*!
 * https://github.com/YouCanBookMe/react-datetime
 */

.rdt {
  position: relative;
}
.rdtPicker {
  display: none;
  position: absolute;
  width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,.1);
  border: 1px solid #f9f9f9;
}
.rdtOpen .rdtPicker {
  display: block;
}
.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
}
.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px;
}
.rdtPicker td {
  cursor: pointer;
}
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer;
}
.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #999999;
}
.rdtPicker td.rdtToday {
  position: relative;
}
.rdtPicker td.rdtToday:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #428bca;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #428bca;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
  color: #999999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker th {
  border-bottom: 1px solid #f9f9f9;
}
.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
  cursor: default;
}
.rdtPicker th.rdtSwitch {
  width: 100px;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker thead tr:first-child th {
  cursor: pointer;
}
.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee;
}

.rdtPicker tfoot {
  border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}
.rdtPicker button:hover {
  background-color: #eee;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters > div {
  float: left;
}

.rdtCounter {
  height: 100px;
}

.rdtCounter {
  width: 40px;
}

.rdtCounterSeparator {
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
}
.rdtCounter .rdtBtn:hover {
  background: #eee;
}
.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}

.rdtTime td {
  cursor: default;
}

.PresetDateRangePicker_panel {
  padding: 0 22px 11px
}
.PresetDateRangePicker_button {
  position: relative;
  height: 100%;
  text-align: center;
  background: 0 0;
  border: 2px solid #00a699;
  color: #00a699;
  padding: 4px 12px;
  margin-right: 8px;
  font: inherit;
  font-weight: 700;
  line-height: normal;
  overflow: visible;
  box-sizing: border-box;
  cursor: pointer
}
.PresetDateRangePicker_button:active {
  outline: 0
}
.PresetDateRangePicker_button__selected {
  color: #fff;
  background: #00a699
}
.SingleDatePickerInput {
  display: inline-block;
  background-color: #fff
}
.SingleDatePickerInput__withBorder {
  border-radius: 2px;
  border: 1px solid #dbdbdb
}
.SingleDatePickerInput__rtl {
  direction: rtl
}
.SingleDatePickerInput__disabled {
  background-color: #f2f2f2
}
.SingleDatePickerInput__block {
  display: block
}
.SingleDatePickerInput__showClearDate {
  padding-right: 30px
}
.SingleDatePickerInput_clearDate {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  padding: 10px;
  margin: 0 10px 0 5px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}
.SingleDatePickerInput_clearDate__default:focus,
.SingleDatePickerInput_clearDate__default:hover {
  background: #dbdbdb;
  border-radius: 50%
}
.SingleDatePickerInput_clearDate__small {
  padding: 6px
}
.SingleDatePickerInput_clearDate__hide {
  visibility: hidden
}
.SingleDatePickerInput_clearDate_svg {
  fill: #82888a;
  height: 12px;
  width: 15px;
  vertical-align: middle
}
.SingleDatePickerInput_clearDate_svg__small {
  height: 9px
}
.SingleDatePickerInput_calendarIcon {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 5px 0 10px
}
.SingleDatePickerInput_calendarIcon_svg {
  fill: #82888a;
  height: 15px;
  width: 14px;
  vertical-align: middle
}
.SingleDatePicker {
  position: relative;
  display: inline-block
}
.SingleDatePicker__block {
  display: block
}
.SingleDatePicker_picker {
  z-index: 1;
  background-color: #fff;
  position: absolute
}
.SingleDatePicker_picker__rtl {
  direction: rtl
}
.SingleDatePicker_picker__directionLeft {
  left: 0
}
.SingleDatePicker_picker__directionRight {
  right: 0
}
.SingleDatePicker_picker__portal {
  background-color: rgba(0,0,0,.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%
}
.SingleDatePicker_picker__fullScreenPortal {
  background-color: #fff
}
.SingleDatePicker_closeButton {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 2
}
.SingleDatePicker_closeButton:focus,
.SingleDatePicker_closeButton:hover {
  color: darken(#cacccd,10%);
  text-decoration: none
}
.SingleDatePicker_closeButton_svg {
  height: 15px;
  width: 15px;
  fill: #cacccd
}
.DayPickerKeyboardShortcuts_buttonReset {
  background: 0 0;
  border: 0;
  border-radius: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  cursor: pointer;
  font-size: 14px
}
.DayPickerKeyboardShortcuts_buttonReset:active {
  outline: 0
}
.DayPickerKeyboardShortcuts_show {
  width: 33px;
  height: 26px;
  position: absolute;
  z-index: 2
}
.DayPickerKeyboardShortcuts_show::before {
  content: "";
  display: block;
  position: absolute
}
.DayPickerKeyboardShortcuts_show__bottomRight {
  bottom: 0;
  right: 0
}
.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-top: 26px solid transparent;
  border-right: 33px solid #00a699;
  bottom: 0;
  right: 0
}
.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
  border-right: 33px solid #008489
}
.DayPickerKeyboardShortcuts_show__topRight {
  top: 0;
  right: 0
}
.DayPickerKeyboardShortcuts_show__topRight::before {
  border-bottom: 26px solid transparent;
  border-right: 33px solid #00a699;
  top: 0;
  right: 0
}
.DayPickerKeyboardShortcuts_show__topRight:hover::before {
  border-right: 33px solid #008489
}
.DayPickerKeyboardShortcuts_show__topLeft {
  top: 0;
  left: 0
}
.DayPickerKeyboardShortcuts_show__topLeft::before {
  border-bottom: 26px solid transparent;
  border-left: 33px solid #00a699;
  top: 0;
  left: 0
}
.DayPickerKeyboardShortcuts_show__topLeft:hover::before {
  border-left: 33px solid #008489
}
.DayPickerKeyboardShortcuts_showSpan {
  color: #fff;
  position: absolute
}
.DayPickerKeyboardShortcuts_showSpan__bottomRight {
  bottom: 0;
  right: 5px
}
.DayPickerKeyboardShortcuts_showSpan__topRight {
  top: 1px;
  right: 5px
}
.DayPickerKeyboardShortcuts_showSpan__topLeft {
  top: 1px;
  left: 5px
}
.DayPickerKeyboardShortcuts_panel {
  overflow: auto;
  background: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  padding: 22px;
  margin: 33px;
  text-align: left
}
.DayPickerKeyboardShortcuts_title {
  font-size: 16px;
  font-weight: 700;
  margin: 0
}
.DayPickerKeyboardShortcuts_list {
  list-style: none;
  padding: 0;
  font-size: 14px
}
.DayPickerKeyboardShortcuts_close {
  position: absolute;
  right: 22px;
  top: 22px;
  z-index: 2
}
.DayPickerKeyboardShortcuts_close:active {
  outline: 0
}
.DayPickerKeyboardShortcuts_closeSvg {
  height: 15px;
  width: 15px;
  fill: #cacccd
}
.DayPickerKeyboardShortcuts_closeSvg:focus,
.DayPickerKeyboardShortcuts_closeSvg:hover {
  fill: #82888a
}
.CalendarDay {
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  text-align: center
}
.CalendarDay:active {
  outline: 0
}
.CalendarDay__defaultCursor {
  cursor: default
}
.CalendarDay__default {
  border: 1px solid #e4e7e7;
  color: #484848;
  background: #fff
}
.CalendarDay__default:hover {
  background: #e4e7e7;
  border: 1px solid #e4e7e7;
  color: inherit
}
.CalendarDay__hovered_offset {
  background: #f4f5f5;
  border: 1px double #e4e7e7;
  color: inherit
}
.CalendarDay__outside {
  border: 0;
  background: #fff;
  color: #484848
}
.CalendarDay__outside:hover {
  border: 0
}
.CalendarDay__blocked_minimum_nights {
  background: #fff;
  border: 1px solid #eceeee;
  color: #cacccd
}
.CalendarDay__blocked_minimum_nights:active,
.CalendarDay__blocked_minimum_nights:hover {
  background: #fff;
  color: #cacccd
}
.CalendarDay__highlighted_calendar {
  background: #ffe8bc;
  color: #484848
}
.CalendarDay__highlighted_calendar:active,
.CalendarDay__highlighted_calendar:hover {
  background: #ffce71;
  color: #484848
}
.CalendarDay__selected_span {
  background: #66e2da;
  border: 1px double #33dacd;
  color: #fff
}
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: #33dacd;
  border: 1px double #33dacd;
  color: #fff
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #00a699;
  border: 1px double #00a699;
  color: #fff
}
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: #b2f1ec;
  border: 1px double #80e8e0;
  color: #007a87
}
.CalendarDay__hovered_span:active {
  background: #80e8e0;
  border: 1px double #80e8e0;
  color: #007a87
}
.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
  background: #cacccd;
  border: 1px solid #cacccd;
  color: #82888a
}
.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
  background: #fff;
  border: 1px solid #e4e7e7;
  color: #cacccd
}
.CalendarDay__hovered_start_first_possible_end {
  background: #eceeee;
  border: 1px double #eceeee
}
.CalendarDay__hovered_start_blocked_min_nights {
  background: #eceeee;
  border: 1px double #e4e7e7
}
.CalendarMonth {
  background: #fff;
  text-align: center;
  vertical-align: top;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}
.CalendarMonth_table {
  border-collapse: collapse;
  border-spacing: 0
}
.CalendarMonth_verticalSpacing {
  border-collapse: separate
}
.CalendarMonth_caption {
  color: #484848;
  font-size: 18px;
  text-align: center;
  padding-top: 22px;
  padding-bottom: 37px;
  caption-side: top;
  caption-side: initial
}
.CalendarMonth_caption__verticalScrollable {
  padding-top: 12px;
  padding-bottom: 7px
}
.CalendarMonthGrid {
  background: #fff;
  text-align: left;
  z-index: 0
}
.CalendarMonthGrid__animating {
  z-index: 1
}
.CalendarMonthGrid__horizontal {
  position: absolute;
  left: 9px
}
.CalendarMonthGrid__vertical {
  margin: 0 auto
}
.CalendarMonthGrid__vertical_scrollable {
  margin: 0 auto;
  overflow-y: scroll
}
.CalendarMonthGrid_month__horizontal {
  display: inline-block;
  vertical-align: top;
  min-height: 100%
}
.CalendarMonthGrid_month__hideForAnimation {
  position: absolute;
  z-index: -1;
  opacity: 0;
  pointer-events: none
}
.CalendarMonthGrid_month__hidden {
  visibility: hidden
}
.DayPickerNavigation {
  position: relative;
  z-index: 2
}
.DayPickerNavigation__horizontal {
  height: 0
}
.DayPickerNavigation__verticalDefault {
  position: absolute;
  width: 100%;
  height: 52px;
  bottom: 0;
  left: 0
}
.DayPickerNavigation__verticalScrollableDefault {
  position: relative
}
.DayPickerNavigation_button {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0;
  padding: 0;
  margin: 0
}
.DayPickerNavigation_button__default {
  border: 1px solid #e4e7e7;
  background-color: #fff;
  color: #757575
}
.DayPickerNavigation_button__default:focus,
.DayPickerNavigation_button__default:hover {
  border: 1px solid #c4c4c4
}
.DayPickerNavigation_button__default:active {
  background: #f2f2f2
}
.DayPickerNavigation_button__disabled {
  cursor: default;
  border: 1px solid #f2f2f2
}
.DayPickerNavigation_button__disabled:focus,
.DayPickerNavigation_button__disabled:hover {
  border: 1px solid #f2f2f2
}
.DayPickerNavigation_button__disabled:active {
  background: 0 0
}
.DayPickerNavigation_button__horizontalDefault {
  position: absolute;
  top: 18px;
  line-height: .78;
  border-radius: 3px;
  padding: 6px 9px
}
.DayPickerNavigation_leftButton__horizontalDefault {
  left: 22px
}
.DayPickerNavigation_rightButton__horizontalDefault {
  right: 22px
}
.DayPickerNavigation_button__verticalDefault {
  padding: 5px;
  background: #fff;
  box-shadow: 0 0 5px 2px rgba(0,0,0,.1);
  position: relative;
  display: inline-block;
  text-align: center;
  height: 100%;
  width: 50%
}
.DayPickerNavigation_nextButton__verticalDefault {
  border-left: 0
}
.DayPickerNavigation_nextButton__verticalScrollableDefault {
  width: 100%
}
.DayPickerNavigation_svg__horizontal {
  height: 19px;
  width: 19px;
  fill: #82888a;
  display: block
}
.DayPickerNavigation_svg__vertical {
  height: 42px;
  width: 42px;
  fill: #484848
}
.DayPickerNavigation_svg__disabled {
  fill: #f2f2f2
}
.DayPicker {
  background: #fff;
  position: relative;
  text-align: left
}
.DayPicker__horizontal {
  background: #fff
}
.DayPicker__verticalScrollable {
  height: 100%
}
.DayPicker__hidden {
  visibility: hidden
}
.DayPicker__withBorder {
  box-shadow: 0 2px 6px rgba(0,0,0,.05),0 0 0 1px rgba(0,0,0,.07);
  border-radius: 3px
}
.DayPicker_portal__horizontal {
  box-shadow: none;
  position: absolute;
  left: 50%;
  top: 50%
}
.DayPicker_portal__vertical {
  position: static;
  position: initial
}
.DayPicker_focusRegion {
  outline: 0
}
.DayPicker_calendarInfo__horizontal,
.DayPicker_wrapper__horizontal {
  display: inline-block;
  vertical-align: top
}
.DayPicker_weekHeaders {
  position: relative
}
.DayPicker_weekHeaders__horizontal {
  margin-left: 9px
}
.DayPicker_weekHeader {
  color: #757575;
  position: absolute;
  top: 62px;
  z-index: 2;
  text-align: left
}
.DayPicker_weekHeader__vertical {
  left: 50%
}
.DayPicker_weekHeader__verticalScrollable {
  top: 0;
  display: table-row;
  border-bottom: 1px solid #dbdbdb;
  background: #fff;
  margin-left: 0;
  left: 0;
  width: 100%;
  text-align: center
}
.DayPicker_weekHeader_ul {
  list-style: none;
  margin: 1px 0;
  padding-left: 0;
  padding-right: 0;
  font-size: 14px
}
.DayPicker_weekHeader_li {
  display: inline-block;
  text-align: center
}
.DayPicker_transitionContainer {
  position: relative;
  overflow: hidden;
  border-radius: 3px
}
.DayPicker_transitionContainer__horizontal {
  -webkit-transition: height .2s ease-in-out;
  transition: height .2s ease-in-out
}
.DayPicker_transitionContainer__vertical {
  width: 100%
}
.DayPicker_transitionContainer__verticalScrollable {
  padding-top: 20px;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: scroll
}
.DateInput {
  margin: 0;
  padding: 0;
  background: #fff;
  position: relative;
  display: inline-block;
  width: 130px;
  vertical-align: middle
}
.DateInput__small {
  width: 97px
}
.DateInput__block {
  width: 100%
}
.DateInput__disabled {
  background: #f2f2f2;
  color: #dbdbdb
}
.DateInput_input {
  font-weight: 200;
  font-size: 19px;
  line-height: 24px;
  color: #484848;
  background-color: #fff;
  width: 100%;
  padding: 11px 11px 9px;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid transparent;
  border-left: 0;
  border-radius: 0
}
.DateInput_input__small {
  font-size: 15px;
  line-height: 18px;
  letter-spacing: .2px;
  padding: 7px 7px 5px
}
.DateInput_input__regular {
  font-weight: auto
}
.DateInput_input__readOnly {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}
.DateInput_input__focused {
  outline: 0;
  background: #fff;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid #008489;
  border-left: 0
}
.DateInput_input__disabled {
  background: #f2f2f2;
  font-style: italic
}
.DateInput_screenReaderMessage {
  border: 0;
  clip: rect(0,0,0,0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px
}
.DateInput_fang {
  position: absolute;
  width: 20px;
  height: 10px;
  left: 22px;
  z-index: 2
}
.DateInput_fangShape {
  fill: #fff
}
.DateInput_fangStroke {
  stroke: #dbdbdb;
  fill: transparent
}
.DateRangePickerInput {
  background-color: #fff;
  display: inline-block
}
.DateRangePickerInput__disabled {
  background: #f2f2f2
}
.DateRangePickerInput__withBorder {
  border-radius: 2px;
  border: 1px solid #dbdbdb
}
.DateRangePickerInput__rtl {
  direction: rtl
}
.DateRangePickerInput__block {
  display: block
}
.DateRangePickerInput__showClearDates {
  padding-right: 30px
}
.DateRangePickerInput_arrow {
  display: inline-block;
  vertical-align: middle;
  color: #484848
}
.DateRangePickerInput_arrow_svg {
  vertical-align: middle;
  fill: #484848;
  height: 24px;
  width: 24px
}
.DateRangePickerInput_clearDates {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  padding: 10px;
  margin: 0 10px 0 5px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}
.DateRangePickerInput_clearDates__small {
  padding: 6px
}
.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
  background: #dbdbdb;
  border-radius: 50%
}
.DateRangePickerInput_clearDates__hide {
  visibility: hidden
}
.DateRangePickerInput_clearDates_svg {
  fill: #82888a;
  height: 12px;
  width: 15px;
  vertical-align: middle
}
.DateRangePickerInput_clearDates_svg__small {
  height: 9px
}
.DateRangePickerInput_calendarIcon {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 5px 0 10px
}
.DateRangePickerInput_calendarIcon_svg {
  fill: #82888a;
  height: 15px;
  width: 14px;
  vertical-align: middle
}
.DateRangePicker {
  position: relative;
  display: inline-block
}
.DateRangePicker__block {
  display: block
}
.DateRangePicker_picker {
  z-index: 1;
  background-color: #fff;
  position: absolute
}
.DateRangePicker_picker__rtl {
  direction: rtl
}
.DateRangePicker_picker__directionLeft {
  left: 0
}
.DateRangePicker_picker__directionRight {
  right: 0
}
.DateRangePicker_picker__portal {
  background-color: rgba(0,0,0,.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%
}
.DateRangePicker_picker__fullScreenPortal {
  background-color: #fff
}
.DateRangePicker_closeButton {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 2
}
.DateRangePicker_closeButton:focus,
.DateRangePicker_closeButton:hover {
  color: darken(#cacccd,10%);
  text-decoration: none
}
.DateRangePicker_closeButton_svg {
  height: 15px;
  width: 15px;
  fill: #cacccd
}

.radiobutton-label {
  margin-left: 10px;
  margin-bottom: unset;
  color: #333333;
}

.radiobutton-description {
  padding-left: 35px;
}

.radiobutton-container:hover {
  cursor: pointer;
}

.radiobutton-label:hover {
  cursor: pointer;
}

.radiobutton-inner.checkbox-open {
 -webkit-transform: scale(.9);
         transform: scale(.9);
}

.radiobutton-inner {
   background-color: #680894;
   border-radius: 100%;
    height: 100%;
    width: 100%;
  -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition-property: transform;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: .30s;
    transition-duration: .30s;
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease;
    -webkit-transition-delay: initial;
    transition-delay: 0s;
    transition-delay: initial;
}

.radiobutton-outer {
  height: 25px;
  width: 25px;
  border: 1px solid #680894;
  border-radius: 100%;
}

.radiogroup-container-vertical > *:not(:last-child){
  margin-bottom: 25px;
}

.radiogroup-container-horizonal > *:not(:last-child){
  margin-right: 25px;
}

.assets-container > *:not(:last-child) {
  margin-bottom: 25px;
}


.negotiations {
  padding: 0%;
}

.negotiations-modal {
  height: 100%;
}


.negotiations-wrapper {
  padding-right: 0px;
  padding-left: 0px;
  position: relative;
}

@media (min-width: 768px) {
  .negotiations-wrapper {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .negotiations-footer {
    padding-bottom: 30px;
  }

  .negotiations-footer {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

@media (max-width: 767px) {
  .negotiations-wrapper {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .negotiations-footer {
    padding-bottom: 15px;
  }

  .negotiations-footer {
    width: 100% !important;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .negotiations-footer {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

.negotiations-messages {
  overflow-y: scroll;
  padding-bottom: 204px;
}

.negotiations-footer {
  background-color: #F8F8F8;
  position: fixed;
  bottom: 0px;
  width: 100%;
  left: 0px;
  right: 0px;
}

.negotiations-modal-body {
  padding-left: 30px;
  margin-right: 30px;
  padding-bottom: 131px;
}

.negotiations-package-name {
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  color: #680894;
  padding-top: 30px;
}

.negotiations-package-description {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 151.1%;
  padding-right: 6%;
  padding-top: 10px;
}

.negotiations-negotiate-pacakge {
  padding-top: 20px;
}

.negotiations-divider {
  height: 1px;
  background-color: #E3DBE6;
  margin-top: 30px;
}

.negotiations-sub-container {
  padding-top: 10px;
}

.negotiations-button-group {
  background: #F2F2F2;
  border-radius: 0px 0px 8px 8px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}

.manage-header {
  margin-bottom: 25px;
  text-align: start;
}


.manage-avatar {
  border-radius: 100%;
}

.manage-me {
  background-color: #3E0359;
}

.manage-them {
  background-color: #EB5B49;
}

.manage-label {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 28px;
  color: white;
}

@media (min-width:  768px) {
  .manage-label {
    font-size: 28px;
  }

  .manage-container  {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .manage-avatar {
    height: 90px;
    width: 90px;
    margin-right: 30px;
  }

  .manage-message {
    padding-right: 30px;
  }
}

@media (max-width:  767px) {
  .manage-label {
    font-size: 16px;
  }

  .manage-container  {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .manage-avatar {
    height: 45px;
    width: 45px;
    margin-right: 15px;
  }
}

.manage-button-group > *:not(:last-child) {
  margin-right: 15px;
}

@media (max-width: 575px) {
  .manage-offer-header > *:not(:last-child) {
    margin-bottom: 15px;
  }

  .manage-offer-details {
    margin-top: 30px;
  }
}

@media (min-width: 576px) {
  .manage-offer-details {
        margin-left: 30px;
  }
}

.manage-header {
  margin-bottom: 25px;
  text-align: start;
}

@media (min-width: 768px) {
  .event-main-panel {
    padding-left: 0px;
  }

  .event-right-panel {
    padding-right: 0px;
  }
}

@media (max-width: 767px) {
  .event-main-panel {
    margin-bottom: 30px;
    padding-left: 0px;
    padding-right: 0px
  }
}

.upload-container {
  border: 1px dashed #4F4F4F;
  width: 100%;
  background-color: #F2F2F2;
  height: 115px;
  border-radius: 2px;
}

.upload-container:hover {
  cursor: pointer;
}


.edit-modal-container > * {
  margin-bottom: 25px;
}

.edit-modal-container {
  padding-top: 60px;
  padding-bottom: 60px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  max-width: 1200px;
}

.edit-modal-wrapper {
  height: 100%;
  overflow-y: scroll;
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.pill-wrapper > *:not(:last-child) {
  margin-right: 15px;
}

.pill-wrapper {
  /*padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;*/
  /*border: 1px solid #680894;*/

}

.pill-wrapper > *:not(:last-child) {
  margin-bottom: 15px;
}

.checkbox-wrapper > *:not(:last-child){
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .checkbox-wrapper > *:not(:nth-child(2)){
    margin-bottom: 15px;
  }
}

.restage-select {
  background: #FBFBFB !important;
  font-size: 16px !important;
  font-family: 'Montserrat', sans-serif !important;
  color: #000000 !important;
  border-radius: 9px !important;
  width: 100%;
}


.restage-select-purple > .ant-select-selection {
  border-color: #680894 !important;
}


.restage-select-purple > .ant-select-selection > .ant-select-selection__rendered > .ant-select-selection__placeholder {
  color: #680894 !important;
}

.autocomplete-entry-group-button {
  height: 40px !important;
  min-height: 40px !important;
  border-radius: 4px;
  min-width: 170px;
}

@media (min-width: 992px)  {
  .autocomplete-entry-group-container > *:first-child {
    margin-right: 12px;
  }
}

@media (max-width: 991px)  {
  .autocomplete-entry-group-container > *:first-child {
    margin-top: 10px;
  }
}


.autocomplete-entry-group-container-vertical > *:first-child {
  margin-bottom: 10px;
}

.audienceprofile-container {

}

.audienceprofile-container > *:not(:last-child) {
  margin-bottom: 25px;
}

.audienceprofile-distance > *:first-child {
  margin-right: 15px;
}

.audienceprofile-distance {
  margin-top: 10px !important;
}

.audienceprofile-distance-hidden {
  display: none;
}



.audienceprofile-attributes-wrapper {
  background-color: #E3DBE6;
}

@media (min-width: 768px) {
  .audienceprofile-attributes-wrapper {
    padding: 25px;
    height: 100%
  }
}

@media (max-width: 767px) {
  .audienceprofile-attributes-wrapper {
    padding: 15px;
  }
}

.edit-modal-container > *, .edit-audience-modal-general-tab > * {
  margin-bottom: 25px;
}

.edit-modal-container {

  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  max-width: 1200px;
}

.edit-modal-wrapper {
  height: 100%;
  overflow-y: scroll;
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

@media (min-width: 845px) {
  .edit-attendee-button {
    padding-bottom: 15px;
  }
}

.eventpackages-sidepanel-container {
  margin-top: 15px;
  list-style-type: disc;
}

.eventpackages-sidepanel-placements {
  list-style-type: disc;
}


.edit-event-package-tab > *:not(:last-child) {
  margin-bottom: 25px;
}

.manage-header {
  margin-bottom: 25px;
  text-align: start;
}

.edit-overview-tab  > *:not(:last-child){
  margin-bottom: 50px;
}

.edit-progress {
  width: 100%;
  height: 200px;
  background-position-x: calc(100% + 30px);
  background-position-y: 10px;
  padding: 10px;
  background-size: 180px;
  background-image: url(/static/media/check.a6ceb50d.svg);
  background-repeat: no-repeat;
  background-color: rgba(235,91,73, .1);
  border-radius: 4px;
  margin-bottom: 35px;
}

.edit-progress-steps > *:not(:last-child){
  margin-right: 15px;
}

.edit-progress-step {
  margin-right: 10px;
}

.edit-modal-open:hover {
  cursor: pointer;
}

.edit-modal-open {
  float: right;
}

.event-overview-documents {
  padding: 35px;
  padding-bottom: 60px !important;
  padding-top: 15px !important;
  background-color: #F8F8F8;
  width: 100%;
}

.event-overview-document-row {
  border-bottom: 1px solid #BDBDBD;
  padding-bottom: 20px;
  padding-top: 20px;
  width: 100%
}

.edit-tab-pencil {
  position: absolute;
  z-index: 1;
}

@media (min-width: 768px) {
  .edit-tab-pencil {
    right: 40px;
    top: 30px;
  }
}

@media (max-width: 767px) {
  .edit-tab-pencil {
    right: 15px;
    top: 30px;
  }
}


.requestsponsorship-container > *:not(:last-child) {
  margin-bottom: 25px;
}


.inputfile {
	display: none
}

.sponsorship-request-form  > *:not(:last-child) {
  margin-bottom: 25px;
}

.eventdetails-sidepanel-container {
  margin-top: 25px;
}

.eventdetails-tilepanel > *:not(:last-child){
  margin-bottom: 15px;
}

.eventdetails-tilepanel {
  margin-top: 15px;
}

.eventdetails-tile-footer {
  padding: 15px;
}

.eventdetails-tile-container {
  border: 1px solid #BECAD6;
  border-radius: 9px;
  overflow: hidden;
}

.eventdetails-sidepanel-image {
  margin-right: 15px;
}

.eventdetails-container > *:not(:last-child) {
  margin-bottom: 25px;
}

.eventdetails-container {
  margin-bottom: 50px;
}

.eventdetails-inputfile {
  display: none;
}

.eventdetails-upload > *:not(:last-child){
  margin-bottom: 25px;
}


.eventpackages-container > *:not(:last-child) {
  margin-bottom: 25px;
}

.eventpackages > *:not(:last-child) {
  margin-bottom: 25px;
}

.eventpackages-sidepanel-container {
  margin-top: 15px;
  list-style-type: disc;
}

.eventpackages-sidepanel-placements {
  list-style-type: disc;
}

.eventpackages-container > *:not(:last-child) {
  margin-bottom: 25px;
}

.eventpackages > *:not(:last-child) {
  margin-bottom: 25px;
}

.eventpackages-placements > *:not(:last-child) {
  border-bottom: 1px solid #E3DBE6;
}

.eventpackages-close-x {
  fill: #FBFBFB;
}
.eventpackages-close {
  background-color: #680894;
  border-radius: 100%;
  padding: 2px;
}

.row-container {
  width: 200px;
  min-width: 200px;
}

.eventreview-container > *:not(:last-child) {
  margin-bottom: 50px;
}

.eventreview-details {
  padding-top: 15px;
  padding-bottom: 30px;
}

/*
@media (max-width: 767px) {
  .review-row > *:first-child {
    margin-bottom: 15px;
  }
}*/


@media (min-width: 768px) {
  .eventreview-image {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .eventreview-image {
    width: 100%;
  }
}

.eventpage-body-packages > *:not(:last-child) {
  margin-bottom: 30px;
}

.portfoliosponsorset-container > *:not(:last-child) {
  margin-bottom: 25px;
}

.objectives-option-container {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 40px;
  padding-right: 40px;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: .25s;
          transition-duration: .25s;
}

.objectives-option-container:hover {
  cursor: pointer;
  color: #EB5B49 !important;
}

.objectivesgroup-header {
  padding-top: 25px;
  padding-bottom: 15px;
  border-bottom: 1px solid #E0E0E0;
  padding-left: 40px;
  padding-right: 40px;
}

.portfolio-objectives-container {
  background-color: #F2F2F2;
  border-color: 1px solid #E0E0E0;
}

.objectivesgroup-container {
  padding-left: 0px !important;
  padding-right: 0px !important;
}



.pillscontainer-attributes-wrapper {
  background-color: #E3DBE6;
}

@media (min-width: 768px) {
  .pillscontainer-attributes-wrapper {
    padding: 25px;
  }
}

@media (max-width: 767px) {
  .pillscontainer-attributes-wrapper {
    padding: 15px;
  }
}

.sponsor-preferences-container > *:not(:last-child) {
  margin-bottom: 25px;
}

.sponsor-preferences-upperbound {
  position: relative;
  margin-left: 25px;
  top: -25px;
}

.portfolioaudience-container > *:not(:last-child) {
  margin-bottom: 25px;
}

.portfolio-placement-types > *:not(:last-child) {
  margin-bottom: 25px;
}

.portfolioreview-details {
  padding-top: 15px;
  padding-bottom: 30px;
}

@media (max-width: 767px) {
  .review-row > *:first-child {
    margin-bottom: 15px;
  }
}


@media (min-width: 768px) {
  .portfolioreview-image {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .portfolioreview-image {
    width: 100%;
  }
}


.search-header {
  width: 100%;
  border-bottom: 1px solid #BECAD6;
}

.search-sidepanel-audience-container {
  padding-top: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
}

@media (max-width: 767px) {
  .search-sidepanel-audience-container {
    padding-left: 15px;
  }

  .search-sidepanel-tabgroup-wrapper {
    padding-left: 15px;
  }

}

.search-sidepanel-tabgroup-wrapper {
  padding-right: 15px;
}



.searchfilters-agerange > *:not(:last-child) {
  margin-bottom: 15px;
}

@media (min-width: 768px ) {
  .searchfilters-container > *:not(:last-child) {
    margin-right: 15px;
    margin-bottom: 15px;
  }
}

@media (max-width: 767px ) {
  .searchfilters-container > *:not(:last-child) {
    margin-bottom: 15px;
  }

  .searchfilters-container {
    padding-bottom: 15px;
  }
}

.eventtile-header {

}

.eventtile-footer {
  border-top: 1px solid #F2F2F2;
}

.eventtile-content, .eventtile-footer {
  padding: 20px;
}

.eventtile-container:hover {
  cursor: pointer;

}

.eventtile-container {
  border-radius: 5px;
  -webkit-transition: box-shadow .5s;
  transition: box-shadow .5s;
  overflow: hidden;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

@media (min-width: 992px) {
  .eventtile-container {
    width: calc(6/12 * 100% - 30px) ;
    padding-left: -15px;
    padding-right: -15px;
  }

  /*.eventtile-container > * {
    padding-left: 15px;
    padding-right: 15px;
  }*/
}

@media (min-width: 768px) and (max-width: 991px) {
  .eventtile-container {
    width: calc(6/12 * 100% - 30px);
  }
}

@media (max-width: 767px) {
  .eventtile-container {
    width: 100%;
  }
}

.eventtile-container:hover {
  cursor: pointer;
}

.eventtile-poster-container {
  padding: 0px !important;
}

.eventtile-poster {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  min-height: 200px;
}

.eventtile-prototype-poster-container {
  padding: 0px !important;
}

.eventtile-prototype-header {

}

.eventtile-prototype-poster {
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  min-height: 200px;
}

.eventtile-prototype-container:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;

}

.eventtile-prototype-container {
  border-radius: 5px;
  -webkit-transition: box-shadow .5s;
  transition: box-shadow .5s;
  border: 1px solid #BECAD6;
  overflow: hidden;
}

@media (min-width: 992px) {
  .eventtile-prototype-container {
    width: calc(12/12 * 100% - 0px) ;
    padding-left: -15px;
    padding-right: -15px;
  }

  /*.eventtile-container > * {
    padding-left: 15px;
    padding-right: 15px;
  }*/
}

@media (min-width: 768px) and (max-width: 991px) {
  .eventtile-prototype-container {
    width: calc(12/12 * 100% - 0px);
  }
}

@media (max-width: 767px) {
  .eventtile-prototype-container {
    width: 100%;
  }
}

.eventtile-prototype-container:hover {
  cursor: pointer;
}

.eventtile-prototype-footer {
  padding: 20px;
}

@media (min-width: 576px) {
  .eventtile-prototype-container {

  }
  .eventtile-prototype-footer {
    height: 200px;
  }

}

@media (max-width: 575px) {
  .eventtile-prototype-container {

  }
}

.search-container {
  /*padding: 15px;*/
}


.search-row > *:not(:last-child) {

}

@media (min-width: 992px) {
  .search-events {
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: -15px;
  }

  .search-events > * {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .search-events {
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: -15px;
  }

  .search-events > * {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .search-events {
    width: 100%;
    margin-bottom: -15px;
  }

  .search-events > * {
    margin-bottom: 30px;
  }

  .search-wrapper {
    padding-top: 30px;
  }
}

/*@media (min-width: 1200px) {
  .search-container {
    max-width: 1400px;
  }
}*/



.search-filter {
  padding-top: 15px;
  padding-bottom: 15px;
}

@media (min-width: 768px) {
  .search-filter > *:not(:last-child){
    margin-right: 15px;
  }

  .search-wrapper {
    padding-top: 50px;
  }

  .search-form {
    border-right: 1px solid #BECAD6;
  }
}

.search-form {
  padding-right: 0px !important;
}

.customize-package-modal-container {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
  height: 100%;
  overflow-y: scroll;
}

.customize-package-modal-form > *:not(:last-child) {
  margin-bottom: 25px;
}

@media (max-width: 767px) {
  .customize-package-modal-reset-button {
    margin-bottom: 30px;
  }
}

.shared-emails-container > *:not(:last-child) {
  margin-bottom: 25px;
}

.share-link {
  margin-top: 30px;
}

.share-link:hover {
  cursor: pointer;
}

.share-event-modal-container {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
}

.share-event-modal-divider-1 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.share-event-modal-divider-2 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.shared-link {
  margin-top: 15px;
}

.event-details-main {
  border-radius: 9px;
}

.event-package-categories > *:not(:last-child) {
  margin-bottom: 30px;
}


@media (min-width: 768px)  {
  .event-toolbar {
    margin-bottom: 30px;
  }

  .event-video-container {
    /*padding-left: 50px;
    padding-right: 50px;*/
  }

  .event-details-main {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .event-details-container {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 50px;
  }

  .event-page-wrapper {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 115px;
  }

  .event-details-panel {
    padding-top: 30px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
  }
}

@media (max-width: 767px)  {
  .event-toolbar {
    margin-bottom: 15px;
  }

  .event-details-panel {
    padding-top: 30px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
  }

  .event-page-wrapper > *:first-child {
      margin-bottom: 15px;
  }

  .event-details-main {

  }
  .event-container {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px !important;
  }

  .event-information {
    padding-bottom: 130px;
    padding-left: 15px;
  }

  .event-gallery {
    position: relative !important;
  }

  .event-details-container {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: 25px;
  }

  .event-page-wrapper {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: 100px;
  }
}

.image-gallery-image img {
 max-height: 400px;
}

.event-gallery {
  height: 300px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.event-gallery-modal {
  height: 100%;
}

.event-sticky-container {
  height: 100%;
}

.event-gallery:hover {
  cursor: pointer;
}

.event-tab-group {
  background-color: white;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  height: 100%;
}

.event-details-wrapper > *:not(:last-child) {
  margin-bottom: 25px;
}

.event-details-container {
  border-radius: 9px;
  background-color: white;
}

.event-details-pill-wrapper > *:not(:last-child){
  margin-bottom: 15px;
}

.event-details-audience-attributes > *:not(:last-child) {
  margin-bottom: 10px;
}

.event-details-packages-content > :not(:last-child) {
  margin-bottom: 15px;
}


.checkbox-wrapper > *:not(:last-child){
  margin-bottom: 15px;
}


.event-details-pill-wrapper > * {
  margin-bottom: 15px;
  margin-right: 15px;
}

.event-overview-tab  > *:not(:last-child){
  margin-bottom: 50px;
}

@media (max-width: 767px) {
  .placement-container > *:first-child {
    margin-bottom: 25px;
  }
}

.cart-footer-container {
  height: 85px;
  background-color: #FBFBFB;
  position: fixed;
  bottom: 0;
  text-align: right;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-top: 1px solid #BECAD6;
}

.cart-footer {
  height: 100%;
}

.footer-purchase {
  padding: 24px !important;
  overflow-y: scroll;
  margin-bottom: 111px;
  /*padding-top: 45px;*/
}

.footer-placements > *:not(:last-child) {
  margin-bottom: 25px;
}

@media (min-width: 768px) {
  .checkout-modal-form-wrapper {
    height: 100%;
  }

  .checkout-modal-footer-sidepanel {
    border-left: 1px solid #e8e8e8
  }
}

@media (min-width: 576px) {
  .footer-column-1 {
    margin-right: 45px;
  }
}

@media (max-width: 575px) {
  .footer-column-1 {
    margin-top: 15px;
  }
}

@media (max-width: 767px) {
  .footer-modal-side-panel {
    padding-top: 45px;
  }

}

.footer-modal-side-panel {
  width: 100%;
}

.footer-modal-selected-placement {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.footer-modal-selected-placements > *:not(:last-child) {
  margin-bottom: 15px;
}

.footer-wrapper {
  z-index: 3;
  position: relative;
}

.footer-signup-container {
  margin-bottom: 75px;
  text-align: center;
}

@media (min-width: 992px) {
  .eventshome-container {
    padding-top: 30px;
    padding-bottom: 65px;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .events-actions {
    margin-top: 35px;
    margin-bottom: 35px;
  }
  .eventshome-container {
    padding-bottom: 65px;
  }
}

.eventshome-emptystate-instructions > *:not(:last-child) {
  margin-bottom: 25px
}


@media (min-width: 768px) {
  .eventshome-emptystate {
    padding-top: 20px;
  }
  .eventshome-emptystate-header, .eventshome-emptystate {
    margin-bottom: 60px;
  }

  .eventshome-emptystate-instructions {
    padding-left: 115px;
  }

  .eventshome-container > * {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 30px;
  }

  .eventshome-container {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.eventshome-emptystate {
  width: 100%;
  max-width: 1000px;
  min-height: 360px;
  background-position-x: calc(100% + 90px);
  background-position-y: 20px;
  background-image: url(/static/media/check.a6ceb50d.svg);
  background-repeat: no-repeat;
  background-color: rgba(235,91,73, .1);
  border-radius: 4px;
}

.eventshome-wrapper {
    padding-top: 30px;
}

.eventshome-emptystate-label {
  margin-right: 20px;
  width: 20px;
}

@media (max-width: 767px) {
  .eventshome-emptystate-instructions {
    padding-left: 30px;
    padding-right: 30px;
  }

  .eventshome-emptystate {
    padding: 20px;
  }
  .eventshome-emptystate-header, .eventshome-emptystate {
    margin-bottom: 30px;
  }

  .events-actions {
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .eventshome-container {
    padding-top: 30px;

    padding-bottom: 35px;
  }

  .eventshome-container > *:not(:last-child) {
    margin-bottom: 30px;
  }
}

.portfoliotile-container {
  min-height: 200px;
  width: 100%;
  border: 1px solid #BECAD6;
  padding: 20px;
}


@media (max-width: 991px) and (min-width: 768px) {
  .sponsor-portfolio-actions {
    margin-top: 35px;
    margin-bottom: 35px;
  }

}

@media (max-width: 767px) {
  .sponsor-portfolio-actions {
    margin-top: 35px;
    margin-bottom: 35px;
  }
}

@media (min-width: 992px) {
  .sponsorhome-container {
    padding-top: 30px;
    padding-bottom: 65px;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .sponsorhome-container {
    padding-bottom: 65px;
  }
}
@media (max-width: 767px) {
  .sponsorhome-container {
    padding-top: 30px;

    padding-bottom: 35px;
  }
}

.portfolioreveiw-section > *:not(:last-child) {
  margin-bottom: 50px;
}

.portfolioreview-details > *:not(:last-child) {
  margin-bottom: 15px;
}

.portfolioreview-eyebrow {
  margin-bottom: 15px;
}

.portfolio-recommendation-row > * {
  padding: 0px !important;
}

.portfolio-recommendations-container > *:not(:last-child) {
  margin-bottom: 25px;
}

.createaccount-form-container > *:not(:last-child) {
  margin-bottom: 25px;
}

.loginform-container > *:not(:last-child) {
  margin-bottom: 25px;
}

.loginform-container {
  width: 100%;
}

@media (min-width: 768px) {
  .createaccount-modal-container {
    padding-left: 125px;
    padding-right: 125px;
    height: 100%;
  }

  .createaccount-modal-form-wrapper {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}

.createaccount-modal-item-visible {
  display: none !important;
}

.createaccount-modal-confirmation-image {
  width: 100%;
  max-width: 250px;
  margin-bottom: 30px;
}

.block-label {
  margin-bottom: 25px;
}

.privacypolicy-container > *:not(:last-child) {
  margin-bottom: 25px;
}

.privacypolicy-container {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media (min-width: 991px) {
  .pagesegment-row > *:first-child {
    padding-right: 50px;
  }
  /*.pagesegment-row > *:first-child {
    padding-right: 50px;
  }

  .pagesegment-row > *:last-child {
    padding-left: 50px;
  }

  .pagesegment-row-reverse > *:first-child {
    padding-left: 50px;
  }*/

  .pagesegment-row-reverse > *:first-child {
    padding-left: 50px;
  }
}

@media (min-width: 768px) {
  .pagesegment-container {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media (max-width: 991px) {
  .pagesegment-row > *:not(:last-child), .pagesegment-row-reverse > *:not(:last-child) {
    margin-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .pagesegment-container {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.pagesegment-container-white {
  background-color: #FFFFFF;
}

.pagesegment-container-purple {
  background: -webkit-gradient(linear, left top, right top, from(#680894), to(#3E0359));
  background: linear-gradient(to right, #680894, #3E0359);

}

.pagesegment-container-pink {
  background: -webkit-gradient(linear, left top, right top, from(#F8A59A), to(#EB5B49));
  background: linear-gradient(to right, #F8A59A, #EB5B49);
}

.pagesegment-header {
  margin-bottom: 10px;
}

.pagesegment-description {
  text-align: start;
  line-height: 151%;
}

.pagesegment-header {
  text-align: start;
  line-height: 32px;
}

.pagesegment-image {
  width: 100%;
  z-index: 3;
}

@media (min-width: 1200px) {
  .pagesegment-image {
    min-width: 595px;
  }
}

.resources-hero-container {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 80%;
  padding-top: 100px;
  padding-bottom: 100px;
  /* background-image: linear-gradient(to bottom, #680894, #EB5B49) with 70% opacity */
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(104, 8, 148, .7)), to(rgba(235, 91, 73, .7)));
  background-image: linear-gradient(to bottom, rgba(104, 8, 148, .7), rgba(235, 91, 73, .7));
}

@media (min-width: 992px) {
  .resources-hero-container {
    height: calc(100vh - 70px);
    min-height: 600px;
  }
}

@media (max-width: 991px) {
  .resources-hero-text {
    margin-bottom: 50px;
  }
}


.resources-hero-button-container {
  margin-top: 60px;
}

.resources-dots-left, .resources-dots-right {
  position: absolute;
  z-index: 1;

}

.resources-dots-left {
  bottom: 0px;
  left: 0px;
}

.resources-dots-right {
  right: 0px;
  top: 0px;
}

.resources-hero-wrapper {
  max-width: 1200px;
  z-index: 2;
}

@media (max-width: 575px) {
  .resources-dots-left, .resources-dots-right {
    width: 100%;
  }
}

.subscribe-container {
  background-image: -webkit-gradient(linear, left top, right top, from(#680894), to(#3E0359));
  background-image: linear-gradient(to right, #680894, #3E0359);
  height: 500px;
  padding: 20px;
  position: relative;
}

@media (min-width: 576px) {
  .subscribe-container {
    width: calc(6/12 * 100% - 30px);
  }
}

@media (min-width: 1200px) {
  .subscribe-container {
    width: 350px;
  }
}

@media (max-width: 575px) {
  .subscribe-container {
    width: 100%;
  }
}

.subscribe-bubbles-top, .subscribe-bubbles-bottom {
  position: absolute;
  z-index: 1;
  height: 220px;
}


.subscribe-bubbles-top {
  top: 0px;
  left: 0px;
}

.subscribe-bubbles-bottom {
  right: 0px;
  bottom: 0px;
}

.blogtile-container {
  border-radius: 5px;
  -webkit-transition: box-shadow .5s;
  transition: box-shadow .5s;
  overflow: hidden;
  height: 500px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.blogtile-container:hover {
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
}

.blogtile-container {
  padding-left: -15px;
  padding-right: -15px;
}

@media (min-width: 576px) {
  .blogtile-container {
    width: calc(6/12 * 100% - 30px);
  }
}

@media (min-width: 1200px) {
  .blogtile-container {
    width: 350px;
  }
}

@media (max-width: 575px) {
  .blogtile-container {
    width: 100%;
  }
}

.blogtile-container:hover {
  cursor: pointer;
}

.blogtile-footer {
  border-top: 1px solid #F2F2F2;
}

.blogtile-footer, .blogtile-content {
  padding: 20px;
}

.blogtile-poster-container {
  padding: 0px !important;
}


.blogtile-poster {
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  min-height: 200px;
}

@media (min-width: 768px) {
  .learnmore-resources-container {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .blogposts-container > * {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 30px;
  }

  .blogposts-container {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media (max-width: 767px) {
  .blogposts-container > * {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 30px;
  }

  .blogposts-container {
    margin-left: -15px;
    margin-right: -15px;
  }

  .learnmore-resources-container {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.learnmore-resources-parts > *:not(:last-child) {
  margin-bottom: 25px;
}

.learnmore-resources-accordion-panel > .ant-collapse-content, .learnmore-resources-accordion-panel {
  background-color: transparent !important;
}

@media (max-width: 991px) {
  .resources-ctas {
    margin-top: 35px;
  }
}

.learnmore-resources-accordion-panel > .ant-collapse-header {
  background-color: #E3DBE6;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
}

.learnmore-resources-accordion-panel > .ant-collapse-content {
  border-left: 1px solid #E0E0E0;
  border-right: 1px solid #E0E0E0;
}


.learnmore-resources-accordion-panel:first-child > .ant-collapse-header {
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
}

@media (min-width: 576px) {
  .resources-create-cta {
    margin-right: 15px;
  }
}


@media (max-width: 575px) {
  .resources-create-cta {
    margin-bottom: 15px;
  }
}

.learnmore-hero-container {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 80%;
  padding-top: 100px;
  padding-bottom: 100px
}

@media (min-width: 992px) {
  .learnmore-hero-container {
    height: calc(100vh - 70px);
    min-height: 600px;
  }
}


.learnmore-hero-button-container {
  margin-top: 60px;
}

.herowithimage-container {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 80%;
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: white;
}

@media (min-width: 992px) {
  .herowithimage-container {
    height: 100vh;
    min-height: 600px;
  }
}

@media (max-width: 991px) {
  .herowithimage-image-container {
    margin-top: 50px;
  }
}


.learnmore-hero-button-container {
  margin-top: 60px;
}

.herowithimage-header, .herowithimage-description {
  text-align: start;
  line-height: 32px;
}

/*
.hero-section-override {
  padding-left: 0px !important;
  padding-right: 0px !important;
}*/

.learnmore-eventorganizer-header {
  width: 100%;
  background-color: white;
}

/*
@media (min-width: 768px) {
  .learnmore-event-organizer-hero-container {
    background-image: url("./assets/hero.svg");
  }
}

@media (max-width: 767px) {
  .learnmore-event-organizer-hero-container {
    background-image: url("./assets/hero-mobile.svg");
  }
}*/

.learnmore-event-organizer-hero-container {
  background-color: white;
}

.eventorganizer-band {
  /*background-image: linear-gradient(to top right, #F8A59A, #EB5B49);*/
  background: linear-gradient(85deg, #680894, 65%, #E3DBE6);
  min-height: 190px;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.eventorganizer-create-img {
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.eventorganizer-dots-left {
  left: 0px;
  position: absolute;
  top: 63%;
  z-index: 2;
}

.eventorganizer-dots-right {
  right: 0px;
  position: absolute;
  top: 25%;
}

@media (min-width: 992px) {
  .learnmore-eventorganizer-header {
    padding-top: 100px;
  }

  .eventorganizer-create-img {
    max-width: 50%;
    width: 100%;
    max-height: 600px;
  }
}

@media (max-width: 991px) {
  .eventorganizer-create-img {
    margin-top: 75px;
  }

  .learnmore-eventorganizer-header {
    padding-top: 150px;
  }

  .eventorganizer-dots-left {
    height: 500px;
  }

  .eventorganizer-dots-right {
    height: 300px;
  }
}


@media (max-width: 767px) and (min-width: 576px) {
  .learnmore-eventorganizer-create-container {
    min-height: 800px;
  }
}

@media (max-width: 575px) {
  .learnmore-eventorganizer-create-container {
    min-height: 700px;
  }

  .eventorganizer-band > *:first-child {
    margin-bottom: 25px;
  }
}

@media (min-width: 1200px) {
  .learnmore-eventorganizer-create-container {
    height: 600px
  }
}

@media (max-width: 1199px) and (min-width: 992px){
  .learnmore-eventorganizer-create-container {
    height: 400px
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .learnmore-eventorganizer-create-container {
    min-height: 650px;
  }

  .eventorganizer-create-img {
    width: 75%;
  }
}

@media (max-width: 767px) {
  .eventorganizer-pagesegments-container > *:first-child {
    padding-bottom: 0px !important;
  }

  .learnmore-eventorganizer-create-container {
    padding-top: 100px;
  }

  .eventorganizer-create-img {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .eventorganizer-pagesegments-container {
    padding-top: 70px;
  }
}

.eventorganizer-pagesegments-container {
  background-color: white;
}

.learnmore-sponsor-header {
  width: 100%;
  background-color: white;
}

/*
@media (min-width: 768px) {
  .learnmore-event-organizer-hero-container {
    background-image: url("./assets/hero.svg");
  }
}

@media (max-width: 767px) {
  .learnmore-event-organizer-hero-container {
    background-image: url("./assets/hero-mobile.svg");
  }
}*/

.learnmore-sponsor-hero-container {
  background-color: white;
}

.sponsor-band {
  background-image: linear-gradient(45deg, #EB5B49, #F8A59A);
  min-height: 190px;
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100%;
}

.sponsor-preferences-img {
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.sponsor-dots-left {
  left: 0px;
  position: absolute;
  top: 63%;
  z-index: 2;
}

.sponsor-dots-right {
  right: 0px;
  position: absolute;
  top: 25%;
}

@media (min-width: 992px) {
  .learnmore-sponsor-header {
    padding-top: 100px;
  }

  .sponsor-preferences-img {
    max-width: 50%;
    bottom: 125px;
  }
}

@media (max-width: 991px) {
  .sponsor-preferences-img {
    margin-top: 75px;
  }

  .learnmore-sponsor-header {
    padding-top: 150px;
  }

  .sponsor-dots-left {
    height: 500px;
  }

  .sponsor-dots-right {
    height: 300px;
  }
}


@media (max-width: 767px) and (min-width: 576px) {
  .learnmore-sponsor-create-container {
    min-height: 800px;
  }
}

@media (max-width: 575px) {
  .learnmore-sponsor-create-container {
    min-height: 700px;
  }


  .sponsor-band > *:first-child {
    margin-bottom: 25px;
  }
}

@media (min-width: 1200px) {
  .learnmore-sponsor-create-container {
    height: 600px
  }
}

@media (max-width: 1199px) and (min-width: 992px){
  .learnmore-sponsor-create-container {
    height: 400px
  }
}

@media (min-width: 768px) {
  .sponsor-pagesegments-container {
    padding-top: 70px;
  }
}

.sponsor-pagesegments-container {
  background-color: white;
}


@media (max-width: 991px) and (min-width: 768px) {
  .learnmore-sponsor-create-container {
    padding-top: 100px;
    min-height: 650px;
  }

  .sponsor-preferences-img {
    width: 75%;
  }
}

@media (max-width: 767px) {
  .sponsor-pagesegments-container > *:first-child {
    padding-bottom: 0px !important;
  }


  .sponsor-preferences-img {
    width: 100%;
  }

  .learnmore-sponsor-create-container {
    padding-top: 100px;
  }
}

.signup-container {
  height: 100%;
}

.signup-form > *:not(:last-child) {
  margin-bottom: 25px;
}

.signup-form > *:nth-child(5) {
  margin-top: 50px;
}

.signup-form {
  width: 100%;
}

.getstarted-container > *:not(:last-child) {
  margin-bottom: 35px;
}

.getstarted-reasons > *:not(:last-child) {
  margin-bottom: 15px;
}

.getstarted-container {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.getstarted-wrapper {
  padding-right: 0px;
  padding-left: 0px;
}

@media (min-width: 768px) {
  .getstarted-wrapper {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .getstarted-content {
    padding: 20px;
    padding-right: 0px !important;
  }
  .getstarted-container {
    padding-top: 25px !important;
    padding-bottom: 50px;
  }

}

@media (max-width: 767px) {
  .getstarted-content {
    padding-top: 20px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .getstarted-container {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .getstarted-wrapper {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

.create-container > *:not(:last-child) {
  margin-bottom: 25px;
}

.create-reasons > *:not(:last-child) {
  margin-bottom: 15px;
}

.create-container {
  padding-left: 0px !important
}

.create-wrapper {
  padding-right: 0px;
  padding-left: 0px;
}

@media (min-width: 768px) {
  .create-wrapper {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .create-content {
    padding-bottom: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
  }
  .create-container {
    padding-top: 25px !important;
    padding-bottom: 0px;
    padding-right: 45px;
    padding-top: 0px !important;
  }

}

@media (max-width: 767px) {
  .create-image {
    display: none;
  }
  .create-content {
    padding-top: 20px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px !important;
  }

  .create-container {
    padding-top: 25px;
    padding-bottom: 25px;
    padding-top: 0px !important;
    padding-right: 0px !important;
  }

  .create-wrapper {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

.create-confirmation-image {
  width: 100%;
  max-width: 250px;
  margin-bottom: 30px;
}



.intro-container > *:not(:last-child) {
  margin-bottom: 35px;
}

.intro-reasons > *:not(:last-child) {
  margin-bottom: 15px;
}

.intro-container {
  padding-left: 0px !important;

}

.intro-wrapper {
  padding-right: 0px;
  padding-left: 0px;
}


.intro-details > *:not(:last-child) {
  margin-bottom: 25px;
}

.intro-circle {
  background-color: #EB5B49;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  margin-right: 25px;
}

@media (min-width: 992px) {
  .intro-image-sm {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .intro-image-sm {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .intro-image-md {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .intro-wrapper {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .intro-content {
    padding-bottom: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
  }
  .intro-container {
    padding-top: 25px !important;
    padding-bottom: 0px;
    padding-right: 45px;
    padding-top: 0px !important;
  }
}

@media (min-width: 768px) {
  .intro-image {
    padding-left: 0px !important;
  }
}

@media (max-width: 767px) {
  .intro-image {
    display: none;
  }

  .intro-content {
    padding-top: 20px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px !important;
  }

  .intro-container {
    padding-top: 25px;
    padding-bottom: 25px;
    padding-right: 0px !important;
    padding-top: 0px !important;
  }

  .intro-wrapper {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

.login-container > *:not(:last-child) {
  margin-bottom: 25px;
}

.login-container {
  width: 100%;
}

.login-container > *:nth-child(3) {
  margin-top: 30px;
}


@font-face {
  font-family: 'champagne-limousines';
  src: url(/static/media/ChampagneAndLimousines.0899368e.ttf) format('truetype');
}

@font-face {
  font-family: 'champagne-limousines-bold';
  src: url(/static/media/ChampagneAndLimousinesBold.e0f9efc0.ttf) format('truetype');
}

@font-face {
  font-family: 'champagne-limousines-bold-italic';
  src: url(/static/media/ChampagneAndLimousinesBoldItalic.065f658d.ttf) format('truetype');
}

@font-face {
  font-family: 'champagne-limousines-italic';
  src: url(/static/media/ChampagneAndLimousinesItalic.deba3472.ttf) format('truetype');
}

.App {
  /*text-align: center;*/
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.app-container {
  /*height: 100vh;*/
  min-height: 100%;
  background-color: #F8F8F8;
  /*background-color: #FFFFFF;*/
}

.restage-route-transition-enter {
  opacity: 0;
}

.restage-route-transition-enter-active {
  opacity: 1;
  -webkit-transition: opacity 600ms;
  transition: opacity 600ms;
}

.restage-route-transition-exit {
  opacity: 1;
}

.restage-route-transition-exit-active {
  opacity: 0;
  -webkit-transition: opacity 600ms;
  transition: opacity 600ms;
}

.restage-route-transition {
  position: absolute;
}

