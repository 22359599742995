@font-face {
  font-family: 'champagne-limousines';
  src: url('./fonts/champagne_limousines/ChampagneAndLimousines.ttf') format('truetype');
}

@font-face {
  font-family: 'champagne-limousines-bold';
  src: url('./fonts/champagne_limousines/ChampagneAndLimousinesBold.ttf') format('truetype');
}

@font-face {
  font-family: 'champagne-limousines-bold-italic';
  src: url('./fonts/champagne_limousines/ChampagneAndLimousinesBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'champagne-limousines-italic';
  src: url('./fonts/champagne_limousines/ChampagneAndLimousinesItalic.ttf') format('truetype');
}

@import "../node_modules/react-image-gallery/styles/css/image-gallery.css";

.App {
  /*text-align: center;*/
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-container {
  /*height: 100vh;*/
  min-height: 100%;
  background-color: #F8F8F8;
  /*background-color: #FFFFFF;*/
}

.restage-route-transition-enter {
  opacity: 0;
}

.restage-route-transition-enter-active {
  opacity: 1;
  transition: opacity 600ms;
}

.restage-route-transition-exit {
  opacity: 1;
}

.restage-route-transition-exit-active {
  opacity: 0;
  transition: opacity 600ms;
}

.restage-route-transition {
  position: absolute;
}
